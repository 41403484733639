import {
  GET_SUBSCRIPTION,
  GET_SUBSCRIPTION_FAIL,
  GET_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_FAIL,
  UPDATE_SUBSCRIPTION_SUCCESS,
  RESET_STATUS,
  GET_SUBSCRIPTIONS,
  GET_SUBSCRIPTIONS_SUCCESS,
  GET_SUBSCRIPTIONS_FAIL,
  CREATE_SUBSCRIPTION,
  CREATE_SUBSCRIPTION_SUCCESS,
  CREATE_SUBSCRIPTION_FAIL,
  DELETE_SUBSCRIPTION,
  DELETE_SUBSCRIPTION_SUCCESS,
  DELETE_SUBSCRIPTION_FAIL,
  RESET_STATE,
  GET_MY_SUBSCRIPTIONS,
  GET_MY_SUBSCRIPTIONS_SUCCESS,
  GET_MY_SUBSCRIPTIONS_FAIL,
} from "./actionTypes";

export const getSubscription = id => ({
  type: GET_SUBSCRIPTION,
  payload: id,
});

export const getSubscriptionSuccess = subscription => ({
  type: GET_SUBSCRIPTION_SUCCESS,
  payload: subscription,
});

export const getSubscriptionFail = error => ({
  type: GET_SUBSCRIPTION_FAIL,
  payload: error,
});

export const getSubscriptions = (
  skip,
  first,
  userId,
  packageId,
  paymentStatus,
  price,
  dateGte,
  dateLte
) => ({
  type: GET_SUBSCRIPTIONS,
  payload: {
    skip,
    first,
    userId,
    packageId,
    paymentStatus,
    price,
    dateGte,
    dateLte,
  },
});

export const getSubscriptionsSuccess = subscriptions => ({
  type: GET_SUBSCRIPTIONS_SUCCESS,
  payload: subscriptions,
});

export const getSubscriptionsFail = error => ({
  type: GET_SUBSCRIPTIONS_FAIL,
  payload: error,
});

export const getMySubscriptions = (
  skip,
  first,
  userId,
  packageId,
  paymentStatus,
  price,
  dateGte,
  dateLte
) => ({
  type: GET_MY_SUBSCRIPTIONS,
  payload: {
    skip,
    first,
    userId,
    packageId,
    paymentStatus,
    price,
    dateGte,
    dateLte,
  },
});

export const getMySubscriptionsSuccess = subscriptions => ({
  type: GET_MY_SUBSCRIPTIONS_SUCCESS,
  payload: subscriptions,
});

export const getMySubscriptionsFail = error => ({
  type: GET_MY_SUBSCRIPTIONS_FAIL,
  payload: error,
});

export const createSubscription = data => ({
  type: CREATE_SUBSCRIPTION,
  payload: data,
});

export const createSubscriptionSuccess = data => ({
  type: CREATE_SUBSCRIPTION_SUCCESS,
  payload: data,
});

export const createSubscriptionFail = error => ({
  type: CREATE_SUBSCRIPTION_FAIL,
  payload: error,
});
export const updateSubscription = data => ({
  type: UPDATE_SUBSCRIPTION,
  payload: data,
});

export const updateSubscriptionSuccess = data => ({
  type: UPDATE_SUBSCRIPTION_SUCCESS,
  payload: data,
});

export const updateSubscriptionFail = error => ({
  type: UPDATE_SUBSCRIPTION_FAIL,
  payload: error,
});

export const deleteSubscription = id => ({
  type: DELETE_SUBSCRIPTION,
  payload: id,
});

export const deleteSubscriptionSuccess = data => ({
  type: DELETE_SUBSCRIPTION_SUCCESS,
  payload: data,
});

export const deleteSubscriptionFail = error => ({
  type: DELETE_SUBSCRIPTION_FAIL,
  payload: error,
});

export const resetState = () => ({
  type: RESET_STATE,
});

export const resetStatus = () => ({
  type: RESET_STATUS,
});
