export class Wallet {
  id: number;
  exchange: string;
  subAccount: string;
  address: string;
  apiKey: string;
  apiSecret: string;
  isMain: boolean;
  transferFee: number;
  //balance: number;

  constructor(
    id: number,
    exchange: string,
    subAccount: string,
    address: string,
    apiKey: string,
    apiSecret: string,
    isMain: boolean,
    transferFee: number
    //balance: number
  ) {
    this.id = id;
    this.exchange = exchange;
    this.subAccount = subAccount;
    this.address = address;
    this.apiKey = apiKey;
    this.apiSecret = apiSecret;
    this.isMain = isMain;
    this.transferFee = transferFee;
    //this.balance = balance;
  }
}
