export const GET_PAYMENT_GATEWAY_SETTINGS = "GET_PAYMENT_GATEWAY_SETTINGS";
export const GET_PAYMENT_GATEWAY_SETTINGS_SUCCESS =
  "GET_PAYMENT_GATEWAY_SETTINGS_SUCCESS";
export const GET_PAYMENT_GATEWAY_SETTINGS_FAIL =
  "GET_PAYMENT_GATEWAY_SETTINGS_FAIL";

export const UPDATE_PAYMENT_GATEWAY_SETTINGS =
  "UPDATE_PAYMENT_GATEWAY_SETTINGS";
export const UPDATE_PAYMENT_GATEWAY_SETTINGS_SUCCESS =
  "UPDATE_PAYMENT_GATEWAY_SETTINGS_SUCCESS";
export const UPDATE_PAYMENT_GATEWAY_SETTINGS_FAIL =
  "UPDATE_PAYMENT_GATEWAY_SETTINGS_FAIL";

export const RESET_STATUS = "RESET_STATUS";
