import { Package } from "core/entities/Package/Package";
import { deleteItemFromArrayById, updateArrayItem } from "helpers/utils";
import {
  GET_PACKAGE,
  GET_PACKAGE_FAIL,
  GET_PACKAGE_SUCCESS,
  UPDATE_PACKAGE,
  UPDATE_PACKAGE_FAIL,
  UPDATE_PACKAGE_SUCCESS,
  RESET_STATUS,
  GET_PACKAGES,
  GET_PACKAGES_SUCCESS,
  GET_PACKAGES_FAIL,
  CREATE_PACKAGE,
  CREATE_PACKAGE_SUCCESS,
  CREATE_PACKAGE_FAIL,
  DELETE_PACKAGE,
  DELETE_PACKAGE_SUCCESS,
  DELETE_PACKAGE_FAIL,
  RESET_STATE,
} from "./actionTypes";

const INIT_STATE = {
  package: {},
  packages: [],
  loading: false,
  saving: false,
  success: false,
  deleted: null,
  error: {},
};

const PaymentGatewayState = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PACKAGE:
      return {
        ...state,
        loading: true,
        error: {},
      };

    case GET_PACKAGE_SUCCESS:
      return {
        ...state,
        package: action.payload,
        loading: false,
        error: {},
      };

    case GET_PACKAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_PACKAGES:
      return {
        ...state,
        loading: true,
        error: {},
      };

    case GET_PACKAGES_SUCCESS:
      return {
        ...state,
        packages: action.payload,
        loading: false,
        error: {},
      };

    case GET_PACKAGES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case CREATE_PACKAGE:
      return {
        ...state,
        saving: true,
        success: false,
        error: {},
      };

    case CREATE_PACKAGE_SUCCESS:
      const createPackages = [...state.packages, action.payload];
      return {
        ...state,
        package: action.payload,
        packages: createPackages,
        saving: false,
        success: true,
        error: {},
      };

    case CREATE_PACKAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        saving: false,
        success: false,
      };

    case UPDATE_PACKAGE:
      return {
        ...state,
        saving: true,
        success: false,
        error: {},
      };

    case UPDATE_PACKAGE_SUCCESS:
      const updatePackages = updateArrayItem(state.packages, action.payload);
      return {
        ...state,
        package: action.payload,
        packages: updatePackages,
        saving: false,
        success: true,
        error: {},
      };

    case UPDATE_PACKAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        saving: false,
        success: false,
      };

    case DELETE_PACKAGE:
      return {
        ...state,
        deleted: null,
        saving: true,
        success: false,
        error: {},
      };

    case DELETE_PACKAGE_SUCCESS:
      const deletePackages = deleteItemFromArrayById(
        state.packages,
        action.payload
      );
      return {
        ...state,
        package: {},
        packages: deletePackages,
        deleted: action.payload,
        saving: false,
        success: true,
        error: {},
      };

    case DELETE_PACKAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        deleted: null,
        saving: false,
        success: false,
      };

    case RESET_STATE:
      let newPackage = new Package(0, "", "", 0, "", "", "", false, 99);
      delete newPackage.id;
      return {
        ...state,
        package: { ...newPackage },
      };

    case RESET_STATUS:
      return {
        ...state,
        error: {},
        deleted: null,
        saving: false,
        success: false,
        loading: false,
      };

    default:
      return state;
  }
};

export default PaymentGatewayState;
