import { put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import {
  GET_PAYMENT_GATEWAY_SETTINGS,
  UPDATE_PAYMENT_GATEWAY_SETTINGS,
} from "./actionTypes";
import {
  getPaymentGatewaySettingsSuccess,
  getPaymentGatewaySettingsFail,
  updatePaymentGatewaySettingsSuccess,
  updatePaymentGatewaySettingsFail,
  resetStatus,
} from "./actions";

import { PaymentGatewaySettingsRepositoryImpl } from "core/infrastructure/PaymentGatewaySettings/PaymentGatewaySettingsRepositoryImpl";
import { PaymentGatewaySettingsServiceImpl } from "core/usecases/PaymentGatewaySettings/PaymentGatewaySettingsService";

function delay(ms) {
  return new Promise(resolve => setTimeout(() => resolve(true), ms));
}

function* fetchSettings() {
  try {
    const PaymentGatewaySettingsRepo =
      new PaymentGatewaySettingsRepositoryImpl();
    const PaymentGatewaySettingsService = new PaymentGatewaySettingsServiceImpl(
      PaymentGatewaySettingsRepo
    );
    const response =
      yield PaymentGatewaySettingsService.GetPaymentGatewaySettings();
    yield put(getPaymentGatewaySettingsSuccess(response));
  } catch (error) {
    yield put(getPaymentGatewaySettingsFail(error));
  }
}

function* updateSettings({ payload }) {
  try {
    const PaymentGatewaySettingsRepo =
      new PaymentGatewaySettingsRepositoryImpl();
    const PaymentGatewaySettingsService = new PaymentGatewaySettingsServiceImpl(
      PaymentGatewaySettingsRepo
    );
    const response =
      yield PaymentGatewaySettingsService.UpdatePaymentGatewaySettings(payload);
    yield put(updatePaymentGatewaySettingsSuccess(response));
    yield delay(3000);
    yield put(resetStatus(response));
  } catch (error) {
    yield put(updatePaymentGatewaySettingsFail(error));
  }
}

function* PaymentGatewaySettingsSaga() {
  yield takeEvery(GET_PAYMENT_GATEWAY_SETTINGS, fetchSettings);
  yield takeEvery(UPDATE_PAYMENT_GATEWAY_SETTINGS, updateSettings);
}

export default PaymentGatewaySettingsSaga;
