export class PaymentGatewaySettings {
  withdrawPeriod: string;
  withdrawTriggerQuota: number;
  responseWebhookUrl: string;
  isActive: boolean;

  constructor(
    withdrawPeriod: string,
    withdrawTriggerQuota: number,
    responseWebhookUrl: string,
    isActive: boolean
  ) {
    this.withdrawPeriod = withdrawPeriod;
    this.withdrawTriggerQuota = withdrawTriggerQuota;
    this.responseWebhookUrl = responseWebhookUrl;
    this.isActive = isActive;
  }
}
