import { client } from "../../HttpClient";
import {
  GET_PACKAGE,
  GET_PACKAGES,
  CREATE_PACKAGE,
  UPDATE_PACKAGE,
  DELETE_PACKAGE,
} from "./graphql";

export const getPackage = async (id: number): Promise<any> => {
  const response = await client.query({
    query: GET_PACKAGE,
    variables: { id },
  });
  return response.data.package;
};

export const getPackages = async (available: boolean): Promise<any> => {
  const response = await client.query({
    query: GET_PACKAGES,
    variables: { available },
  });
  return response.data.packages;
};

export const createPackage = async (data: object): Promise<any> => {
  const response = await client.mutate({
    mutation: CREATE_PACKAGE,
    variables: {
      data: data,
    },
  });
  return response.data.createPackage.package;
};

export const updatePackage = async (data: object): Promise<any> => {
  const response = await client.mutate({
    mutation: UPDATE_PACKAGE,
    variables: {
      data: data,
    },
  });
  return response.data.updatePackage.package;
};

export const deletePackage = async (id: number): Promise<any> => {
  const response = await client.mutate({
    mutation: DELETE_PACKAGE,
    variables: {
      id,
    },
  });
  return response.data.deletePackage.package;
};
