import { Auth } from "../../entities/Auth/Auth";
import { AuthRepository } from "../../entities/Auth/AuthRepository";

export interface AuthService {
  ExchangeCode(code: string): Promise<Auth>;
  RefreshToken(refreshToken: string): Promise<Auth>;
  RevokeToken(token: string): Promise<Auth>;
}

export class AuthServiceImpl implements AuthService {
  authRepo: AuthRepository;

  constructor(ar: AuthRepository) {
    this.authRepo = ar;
  }

  async ExchangeCode(code: string): Promise<Auth> {
    return this.authRepo.ExchangeCode(code);
  }

  async RefreshToken(refreshToken: string): Promise<Auth> {
    return this.authRepo.RefreshToken(refreshToken);
  }

  async RevokeToken(token: string): Promise<Auth> {
    return this.authRepo.RevokeToken(token);
  }
}
