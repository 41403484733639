import { put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_CRON_LOGS } from "./actionTypes";
import { getCronLogsSuccess, getCronLogsFail } from "./actions";

import { CronLogRepositoryImpl } from "core/infrastructure/CronLog/CronLogRepositoryImpl";
import { CronLogServiceImpl } from "core/usecases/CronLog/CronLogService";

function* fetchCronLogs({ payload }) {
  const { skip, first, dateGte, dateLte } = payload;
  try {
    const CronLogRepo = new CronLogRepositoryImpl();
    const CronLogService = new CronLogServiceImpl(CronLogRepo);
    const response = yield CronLogService.GetCronLogs(
      skip,
      first,
      dateGte,
      dateLte
    );
    yield put(getCronLogsSuccess(response));
  } catch (error) {
    yield put(getCronLogsFail(error));
  }
}

function* CronLogSaga() {
  yield takeEvery(GET_CRON_LOGS, fetchCronLogs);
}

export default CronLogSaga;
