import { combineReducers } from "redux";

import Layout from "./layout/reducer";

import Auth from "./auth/reducer";

import PackageState from "./package/reducer";

import PaymentGatewayState from "./payment-gateway-settings/reducer";

import WalletState from "./wallet/reducer";

import RoleState from "./role/reducer";

import SubscriptionState from "./subscription/reducer";

import UserState from "./user/reducer";

import PaymentState from "./payment/reducer";

import ErrorHandlerState from "./error-handler/reducer";

import CronLogState from "./cron-log/reducer";

//E-commerce
import ecommerce from "./e-commerce/reducer";

//Calendar
import calendar from "./calendar/reducer";

//chat
import chat from "./chat/reducer";

//crypto
import crypto from "./crypto/reducer";

//invoices
import invoices from "./invoices/reducer";

//projects
import projects from "./projects/reducer";

//tasks
import tasks from "./tasks/reducer";

//contacts
import contacts from "./contacts/reducer";

//mails
import mails from "./mails/reducer";

//Dashboard
import Dashboard from "./dashboard/reducer";

//Dasboard saas
import DashboardSaas from "./dashboard-saas/reducer";

const rootReducer = combineReducers({
  // public
  Auth,
  CronLogState,
  Layout,
  PackageState,
  PaymentGatewayState,
  PaymentState,
  RoleState,
  SubscriptionState,
  UserState,
  WalletState,
  ErrorHandlerState,
  ecommerce,
  calendar,
  chat,
  mails,
  crypto,
  invoices,
  projects,
  tasks,
  contacts,
  Dashboard,
  DashboardSaas,
});

export default rootReducer;
