import { REVOKE_TOKEN } from "core/infrastructure/Services/api-service/graphql";
import {
  EXCHANGE_CODE,
  EXCHANGE_CODE_FAIL,
  EXCHANGE_CODE_SUCCESS,
  REFRESH_TOKEN,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAIL,
  REVOKE_TOKEN_SUCCESS,
  REVOKE_TOKEN_FAIL,
  LOGOUT,
} from "./actionTypes";

const INIT_STATE = {
  auth: {},
  loading: false,
  error: {},
};

const Auth = (state = INIT_STATE, action) => {
  switch (action.type) {
    case EXCHANGE_CODE:
      return {
        ...state,
        loading: true,
        error: {},
      };

    case EXCHANGE_CODE_SUCCESS:
      return {
        ...state,
        auth: action.payload,
        loading: false,
        error: {},
      };

    case EXCHANGE_CODE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case REFRESH_TOKEN:
      return {
        ...state,
        loading: true,
        error: {},
      };

    case REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        auth: action.payload,
        loading: false,
        error: {},
      };

    case REFRESH_TOKEN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case REVOKE_TOKEN:
      return {
        ...state,
        error: {},
        loading: true,
      };

    case REVOKE_TOKEN_SUCCESS:
      return {
        ...state,
        auth: action.payload,
        loading: false,
        error: {},
      };

    case REVOKE_TOKEN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case LOGOUT:
      return {
        ...state,
        auth: {},
        loading: false,
        error: {},
      };

    default:
      return state;
  }
};

export default Auth;
