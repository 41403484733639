export const GET_SUBSCRIPTION = "GET_SUBSCRIPTION";
export const GET_SUBSCRIPTION_SUCCESS = "GET_SUBSCRIPTION_SUCCESS";
export const GET_SUBSCRIPTION_FAIL = "GET_SUBSCRIPTION_FAIL";

export const GET_SUBSCRIPTIONS = "GET_SUBSCRIPTIONS";
export const GET_SUBSCRIPTIONS_SUCCESS = "GET_SUBSCRIPTIONS_SUCCESS";
export const GET_SUBSCRIPTIONS_FAIL = "GET_SUBSCRIPTIONS_FAIL";

export const CREATE_SUBSCRIPTION = "CREATE_SUBSCRIPTION";
export const CREATE_SUBSCRIPTION_SUCCESS = "CREATE_SUBSCRIPTION_SUCCESS";
export const CREATE_SUBSCRIPTION_FAIL = "CREATE_SUBSCRIPTION_FAIL";

export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";
export const UPDATE_SUBSCRIPTION_SUCCESS = "UPDATE_SUBSCRIPTION_SUCCESS";
export const UPDATE_SUBSCRIPTION_FAIL = "UPDATE_SUBSCRIPTION_FAIL";

export const DELETE_SUBSCRIPTION = "DELETE_SUBSCRIPTION";
export const DELETE_SUBSCRIPTION_SUCCESS = "DELETE_SUBSCRIPTION_SUCCESS";
export const DELETE_SUBSCRIPTION_FAIL = "DELETE_SUBSCRIPTION_FAIL";

export const GET_MY_SUBSCRIPTIONS = "GET_MY_SUBSCRIPTIONS";
export const GET_MY_SUBSCRIPTIONS_SUCCESS = "GET_MY_SUBSCRIPTIONS_SUCCESS";
export const GET_MY_SUBSCRIPTIONS_FAIL = "GET_MY_SUBSCRIPTIONS_FAIL";

export const RESET_STATE = "RESET_STATE";

export const RESET_STATUS = "RESET_STATUS";
