import React from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { createWallet, resetState } from "store/actions";
import { withEditableResource } from "../../hocs/withEditableResource";

const getSourceFunc = resetState;
const setSourceFunc = createWallet;
const selectorFunc = state => state.WalletState.wallet;
const resourceName = "wallet";
const statusSelectorFunc = state => {
  return {
    saving: state.WalletState.saving,
    success: state.WalletState.success,
    error: state.WalletState.error,
  };
};

export const WalletCreateForm = withEditableResource(
  ({ wallet, status, onChangeWallet, onSaveWallet, onResetWallet }) => {
    const {
      exchange,
      subAccount,
      address,
      apiKey,
      apiSecret,
      isMain,
      transferFee,
    } = wallet || {};

    return wallet ? (
      <>
        <Form>
          <FormGroup className="mb-3">
            <Label for="exchange">Exchange</Label>
            <Input
              id="exchange"
              name="exchange"
              placeholder="Enter exchange api name..."
              type="text"
              value={exchange}
              onChange={e => onChangeWallet({ exchange: e.target.value })}
            />
          </FormGroup>
          <FormGroup className="mb-3">
            <Label for="subAccount">Sub Account</Label>
            <Input
              id="subAccount"
              name="subAccount"
              placeholder="Enter sub account..."
              type="text"
              value={subAccount}
              onChange={e =>
                onChangeWallet({
                  subAccount: e.target.value,
                })
              }
            />
          </FormGroup>
          <FormGroup className="mb-3">
            <Label for="address">Address</Label>
            <Input
              id="address"
              name="address"
              placeholder="Enter address..."
              type="text"
              value={address}
              onChange={e => onChangeWallet({ address: e.target.value })}
            />
          </FormGroup>
          <FormGroup className="mb-3">
            <Label for="apiKey">Api Key</Label>
            <Input
              id="apiKey"
              name="apiKey"
              placeholder="Enter api key..."
              type="text"
              value={apiKey}
              onChange={e => onChangeWallet({ apiKey: e.target.value })}
            />
          </FormGroup>
          <FormGroup className="mb-3">
            <Label for="apiSecret">Api Secret</Label>
            <Input
              id="apiSecret"
              name="apiSecret"
              placeholder="Enter api secret..."
              type="text"
              value={apiSecret}
              onChange={e => onChangeWallet({ apiSecret: e.target.value })}
            />
          </FormGroup>

          <div className="form-check form-switch form-switch-md mb-3">
            <input
              type="checkbox"
              className="form-check-input"
              id="isMain"
              checked={isMain}
              onChange={e => onChangeWallet({ isMain: e.target.checked })}
            />
            <label className="form-check-label" htmlFor="isMain">
              Is Main?
            </label>
          </div>

          <FormGroup className="mb-3">
            <Label for="transferFee">Transfer Fee</Label>
            <Input
              id="transferFee"
              name="transferFee"
              placeholder="Enter transfer fee..."
              type="text"
              value={transferFee}
              onChange={e =>
                onChangeWallet({ transferFee: Number(e.target.value) })
              }
            />
          </FormGroup>
        </Form>

        <Button
          color="primary"
          className="w-md me-2"
          onClick={onSaveWallet}
          disabled={status.saving}
        >
          {status.saving ? (
            <i className="fas fa-circle-notch fa-spin"></i>
          ) : status.success ? (
            <i className="fas fa-check "></i>
          ) : (
            "Save"
          )}
        </Button>
        <Button
          color="light"
          className="w-md"
          onClick={onResetWallet}
          disabled={status.saving}
        >
          Reset
        </Button>
      </>
    ) : (
      <p>Loading...</p>
    );
  },
  resourceName,
  getSourceFunc,
  setSourceFunc,
  selectorFunc,
  statusSelectorFunc
);
