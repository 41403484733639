export const GET_PAYMENT_STATUS = "GET_PAYMENT_STATUS";
export const GET_PAYMENT_STATUS_SUCCESS = "GET_PAYMENT_STATUS_SUCCESS";
export const GET_PAYMENT_STATUS_FAIL = "GET_PAYMENT_STATUS_FAIL";

export const CREATE_PAYMENT = "CREATE_PAYMENT";
export const CREATE_PAYMENT_SUCCESS = "CREATE_PAYMENT_SUCCESS";
export const CREATE_PAYMENT_FAIL = "CREATE_PAYMENT_FAIL";

export const CANCEL_PAYMENT = "CANCEL_PAYMENT";
export const CANCEL_PAYMENT_SUCCESS = "CANCEL_PAYMENT_SUCCESS";
export const CANCEL_PAYMENT_FAIL = "CANCEL_PAYMENT_FAIL";

export const RESET_STATE = "RESET_STATE";

export const RESET_STATUS = "RESET_STATUS";
