import { User } from "../../entities/User/User";
import { Auth } from "../../entities/Auth/Auth";
import { AuthRepository } from "../../entities/Auth/AuthRepository";

import services from "../Services";

class AuthDTO {
  accessToken: string = "";
  tokenType: string = "";
  expiresIn: number = 0;
  refreshToken: string = "";
  scope: string = "";
  user: User = new User("", "", "", "");
}

export class AuthRepositoryImpl implements AuthRepository {
  async ExchangeCode(code: string): Promise<Auth> {
    const auth: AuthDTO = await services.api.exchangeCode(code);
    return new Auth(
      auth.accessToken,
      auth.tokenType,
      auth.expiresIn,
      auth.refreshToken,
      auth.scope,
      auth.user
    );
  }

  async RefreshToken(refreshToken: string): Promise<Auth> {
    const auth: AuthDTO = await services.api.refreshToken(refreshToken);
    return new Auth(
      auth.accessToken,
      auth.tokenType,
      auth.expiresIn,
      auth.refreshToken,
      auth.scope,
      auth.user
    );
  }

  async RevokeToken(token: string): Promise<Auth> {
    const auth: AuthDTO = await services.api.revokeToken(token);
    return new Auth(
      auth.accessToken,
      auth.tokenType,
      auth.expiresIn,
      auth.refreshToken,
      auth.scope,
      auth.user
    );
  }
}
