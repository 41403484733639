import { RESET_ERROR } from "./actionTypes";

const INIT_STATE = {
  error: null,
};

const ErrorHandlerState = (state = INIT_STATE, action) => {
  const { payload } = action;

  if (payload instanceof Error) {
    return {
      error: payload,
    };
  } else if (action.type === RESET_ERROR) {
    return {
      error: null,
    };
  }
  return state;
};

export default ErrorHandlerState;
