import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import { useDataSource } from "hooks/useDataSource";
import { Button, Container, Modal, ModalBody } from "reactstrap";
import { getPackages, getUsers } from "store/actions";
import { SubscriptionUpdate } from "./subscriptionUpdateForm";
import { SubscriptionCreate } from "./subscriptionCreateForm";
import { SubscriptionList } from "./subscriptionList";

const Subscriptions = () => {
  const packages = useDataSource(
    getPackages,
    state => state.PackageState.packages
  );
  const users = useDataSource(
    () => getUsers(1000),
    state => state.UserState.users
  );
  const [openModal, setOpenModal] = useState({
    isOpen: false,
    isCreate: false,
    isUpdate: false,
    selectedId: null,
  });

  const onRowClick = row => {
    setOpenModal(prevState => {
      return { ...prevState, isOpen: true, isUpdate: true, selectedId: row.id };
    });
  };

  const handleCreate = () => {
    setOpenModal(prevState => {
      return { ...prevState, isOpen: true, isCreate: true };
    });
  };

  const closeModal = () => {
    setOpenModal({
      isOpen: false,
      isCreate: false,
      isUpdate: false,
      selectedId: null,
    });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Üyelikler</title>
        </MetaTags>
        <Container fluid>
          <Button
            color="primary"
            className="float-end"
            onClick={handleCreate}
            style={{ marginTop: -5 }}
          >
            <i className="fa fa-plus"></i> Ekle
          </Button>
          <h4 className="mb-3">Üyelikler</h4>
        </Container>

        <SubscriptionList packages={packages} onRowClick={onRowClick} />

        <Modal isOpen={openModal.isOpen} toggle={closeModal}>
          <ModalBody>
            <Button close onClick={closeModal} />
            {openModal.isCreate && (
              <SubscriptionCreate packages={packages} users={users} />
            )}
            {openModal.isUpdate && (
              <SubscriptionUpdate
                id={openModal.selectedId}
                packages={packages}
                users={users}
              />
            )}
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default Subscriptions;
