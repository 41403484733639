import { PaymentGatewaySettings } from "../../entities/PaymentGatewaySettings/PaymentGatewaySettings";
import { PaymentGatewaySettingsRepository } from "../../entities/PaymentGatewaySettings/PaymentGatewaySettingsRepository";

export interface PaymentGatewaySettingsService {
  GetPaymentGatewaySettings(): Promise<PaymentGatewaySettings>;
  UpdatePaymentGatewaySettings(
    data: PaymentGatewaySettings
  ): Promise<PaymentGatewaySettings>;
}

export class PaymentGatewaySettingsServiceImpl
  implements PaymentGatewaySettingsService
{
  pgRepo: PaymentGatewaySettingsRepository;

  constructor(ar: PaymentGatewaySettingsRepository) {
    this.pgRepo = ar;
  }

  async GetPaymentGatewaySettings(): Promise<PaymentGatewaySettings> {
    return this.pgRepo.Get();
  }

  async UpdatePaymentGatewaySettings(
    data: PaymentGatewaySettings
  ): Promise<PaymentGatewaySettings> {
    return this.pgRepo.Update(data);
  }
}
