export const GET_PACKAGE = "GET_PACKAGE";
export const GET_PACKAGE_SUCCESS = "GET_PACKAGE_SUCCESS";
export const GET_PACKAGE_FAIL = "GET_PACKAGE_FAIL";

export const GET_PACKAGES = "GET_PACKAGES";
export const GET_PACKAGES_SUCCESS = "GET_PACKAGES_SUCCESS";
export const GET_PACKAGES_FAIL = "GET_PACKAGES_FAIL";

export const CREATE_PACKAGE = "CREATE_PACKAGE";
export const CREATE_PACKAGE_SUCCESS = "CREATE_PACKAGE_SUCCESS";
export const CREATE_PACKAGE_FAIL = "CREATE_PACKAGE_FAIL";

export const UPDATE_PACKAGE = "UPDATE_PACKAGE";
export const UPDATE_PACKAGE_SUCCESS = "UPDATE_PACKAGE_SUCCESS";
export const UPDATE_PACKAGE_FAIL = "UPDATE_PACKAGE_FAIL";

export const DELETE_PACKAGE = "DELETE_PACKAGE";
export const DELETE_PACKAGE_SUCCESS = "DELETE_PACKAGE_SUCCESS";
export const DELETE_PACKAGE_FAIL = "DELETE_PACKAGE_FAIL";

export const RESET_STATE = "RESET_STATE";

export const RESET_STATUS = "RESET_STATUS";
