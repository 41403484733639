import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import PaymentResult from "./paymentResult";
import QRCode from "react-qr-code";
import { Row, Col, Card, Button } from "reactstrap";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useDispatch, useSelector } from "react-redux";
import { handleExpireDuration } from "helpers/utils";
import { cancelPayment, getPaymentStatus } from "store/actions";
import { CopyToClipboard } from "react-copy-to-clipboard";

const PaymentBox = () => {
  const dispatch = useDispatch();
  const { payment } = useSelector(state => state.PaymentState);
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    let myInterval = setInterval(() => {
      const id = Number(payment.orderId);
      dispatch(getPaymentStatus(id));
    }, 10000);

    return () => {
      clearInterval(myInterval);
    };
  }, []);

  const handleCancelPayment = () => {
    dispatch(
      cancelPayment({
        walletId: payment.walletId,
        orderId: parseInt(payment.orderId),
      })
    );
  };

  const handleOnCopy = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  return (
    <React.Fragment>
      <Row className="text-center text-sm-left">
        <Col>
          <Card className="bg-white p-2 my-4 mx-auto" style={{ width: 143 }}>
            {payment.walletAddress && (
              <QRCode
                value={payment.walletAddress}
                size={128}
                style={{ with: "100px" }}
              />
            )}
          </Card>
        </Col>
        <Col>
          <div className="m-4 mx-3">
            <div>
              <p className="text-muted mb-1">Send Exactly</p>
              <h4>{payment.amount} USDT</h4>
            </div>
            <hr />
            <div>
              <p className="text-muted mb-1">USDT Address</p>
              <h4>{payment.walletAddress}</h4>
              <CopyToClipboard
                text={payment.walletAddress}
                onCopy={handleOnCopy}
              >
                <Button color="link">
                  <span className="mdi mdi-content-copy"></span>{" "}
                  {isCopied ? "Copied!" : "Copy Address"}
                </Button>
              </CopyToClipboard>
            </div>
          </div>
        </Col>
        <Col>
          <div className="my-4 mx-auto d-inline-block">
            <CountdownCircleTimer
              isPlaying
              duration={handleExpireDuration(payment.paymentExpire)}
              colors={[
                ["#195DF6", 0.16],
                ["#118AF4", 0.16],
                ["#02DCF0", 0.16],
                ["#F7B801", 0.16],
                ["#F7B801", 0.16],
                ["#A30000", 0.16],
              ]}
              size={144}
            >
              {({ remainingTime }) => remainingTime}
            </CountdownCircleTimer>
          </div>
        </Col>
      </Row>

      <div className="text-center pb-3">
        <Button color="primary" onClick={handleCancelPayment}>
          Vazgeç
        </Button>
      </div>

      {payment && <PaymentResult paymentStatus={payment.paymentStatus} />}
    </React.Fragment>
  );
};

PaymentBox.propTypes = {
  payment: PropTypes.string,
  paymentStatus: PropTypes.string,
};

export default PaymentBox;
