import { client } from "../../HttpClient";
import { EXCHANGE_CODE, REFRESH_TOKEN, REVOKE_TOKEN } from "./graphql";

export const exchangeCode = async (code: string): Promise<any> => {
  const response = await client.mutate({
    mutation: EXCHANGE_CODE,
    variables: { code },
  });
  return response.data.exchangeCode.response;
};

export const refreshToken = async (refreshToken: string): Promise<any> => {
  const response = await client.mutate({
    mutation: REFRESH_TOKEN,
    variables: { refreshToken },
  });
  return response.data.refreshToken.response;
};

export const revokeToken = async (token: string): Promise<any> => {
  const response = await client.mutate({
    mutation: REVOKE_TOKEN,
    variables: { token },
  });
  return response.data.revokeToken.response;
};
