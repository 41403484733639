import React from "react";
import PropTypes from "prop-types";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { getSubscription, updateSubscription } from "store/actions";
import { withEditableResource } from "../../hocs/withEditableResource";
import { PaymentStatuses } from "core/entities/Subscription/SubscriptionTypes";

const getSourceFunc = id => () => getSubscription(id);
const setSourceFunc = updateSubscription;
const selectorFunc = state => state.SubscriptionState.subscription;
const resourceName = "subscription";
const statusSelectorFunc = state => {
  return {
    saving: state.SubscriptionState.saving,
    success: state.SubscriptionState.success,
    error: state.SubscriptionState.error,
  };
};

const SubscriptionUpdateForm = (id, users, packages) =>
  withEditableResource(
    ({
      subscription,
      status,
      onChangeSubscription,
      onSaveSubscription,
      onResetSubscription,
    }) => {
      const { userName, duration, price, packageId, paymentStatus } =
        subscription || {};

      return subscription ? (
        <>
          <Form>
            <FormGroup className="mb-3">
              <Label for="userName">User</Label>
              <Input
                id="userName"
                name="userName"
                type="text"
                value={userName}
                readOnly
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <Label for="packageId">Package</Label>
              <Input
                id="package"
                name="package"
                type="text"
                value={subscription?.package?.name}
                readOnly
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <Label for="duration">Duration in Days</Label>
              <Input
                id="duration"
                name="duration"
                placeholder="Enter duration..."
                type="text"
                value={duration}
                readOnly
                onChange={e =>
                  onChangeSubscription({ duration: Number(e.target.value) })
                }
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <Label for="price">Price</Label>
              <Input
                id="price"
                name="price"
                placeholder="Enter price..."
                type="text"
                value={price}
                readOnly
                onChange={e => onChangeSubscription({ price: e.target.value })}
              />
            </FormGroup>

            <FormGroup className="mb-3">
              <Label for="paymentStatus">Payment Status</Label>
              <Input
                id="paymentStatus"
                name="paymentStatus"
                type="select"
                value={paymentStatus}
                onChange={e =>
                  onChangeSubscription({
                    paymentStatus: e.target.value,
                  })
                }
              >
                <option>Select Status</option>
                {Object.values(PaymentStatuses)
                  .filter(value => typeof value === "number")
                  .map((key, i) => (
                    <option key={i}>{PaymentStatuses[key]}</option>
                  ))}
              </Input>
            </FormGroup>
          </Form>

          <Button
            color="primary"
            className="w-md me-2"
            onClick={onSaveSubscription}
            disabled={status.saving}
          >
            {status.saving ? (
              <i className="fas fa-circle-notch fa-spin"></i>
            ) : status.success ? (
              <i className="fas fa-check "></i>
            ) : (
              "Save"
            )}
          </Button>
          <Button
            color="light"
            className="w-md"
            onClick={onResetSubscription}
            disabled={status.saving}
          >
            Reset
          </Button>
        </>
      ) : (
        <p>Loading...</p>
      );
    },
    resourceName,
    getSourceFunc(id),
    setSourceFunc,
    selectorFunc,
    statusSelectorFunc
  );

export const SubscriptionUpdate = ({ id, packages, users }) => {
  const Component = SubscriptionUpdateForm(id, users, packages);
  return <Component />;
};

SubscriptionUpdate.propTypes = {
  id: PropTypes.number.isRequired,
  packages: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
};
