import {
  GET_CRON_LOGS,
  GET_CRON_LOGS_SUCCESS,
  GET_CRON_LOGS_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  cronLogs: {},
  loading: false,
  error: {},
};

const CronLogState = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CRON_LOGS:
      return {
        ...state,
        loading: true,
        error: {},
      };

    case GET_CRON_LOGS_SUCCESS:
      return {
        ...state,
        cronLogs: action.payload,
        loading: false,
        error: {},
      };

    case GET_CRON_LOGS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default CronLogState;
