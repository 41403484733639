import {
  CancelPaymentDTO,
  CreatePaymentDTO,
  PaymentStatusDTO,
} from "core/entities/Payment/PaymentTypes";
import { Payment } from "../../entities/Payment/Payment";
import { PaymentRepository } from "../../entities/Payment/PaymentRepository";

export interface PaymentService {
  GetPaymentStatus(id: number): Promise<PaymentStatusDTO>;
  CreatePayment(data: CreatePaymentDTO): Promise<Payment>;
  CancelPayment(data: CancelPaymentDTO): Promise<Payment>;
}

export class PaymentServiceImpl implements PaymentService {
  paymentRepo: PaymentRepository;

  constructor(ar: PaymentRepository) {
    this.paymentRepo = ar;
  }

  async GetPaymentStatus(id: number): Promise<PaymentStatusDTO> {
    return this.paymentRepo.GetStatusById(id);
  }

  async CreatePayment(data: CreatePaymentDTO): Promise<Payment> {
    return this.paymentRepo.Create(data);
  }

  async CancelPayment(data: CancelPaymentDTO): Promise<Payment> {
    return this.paymentRepo.Cancel(data);
  }
}
