import React from "react";
import PropTypes from "prop-types";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { createSubscription, resetState } from "store/actions";
import { withEditableResource } from "../../hocs/withEditableResource";
import { PaymentStatuses } from "core/entities/Subscription/SubscriptionTypes";
import { usersToSelectOptions } from "helpers/utils";
import Select from "react-select";

const getSourceFunc = resetState;
const setSourceFunc = createSubscription;
const selectorFunc = state => state.SubscriptionState.subscription;
const resourceName = "subscription";
const statusSelectorFunc = state => {
  return {
    saving: state.SubscriptionState.saving,
    success: state.SubscriptionState.success,
    error: state.SubscriptionState.error,
  };
};

const SubscriptionCreateForm = (users, packages) =>
  withEditableResource(
    ({
      subscription,
      status,
      onChangeSubscription,
      onSaveSubscription,
      onResetSubscription,
    }) => {
      const { userId, packageId, duration, price, paymentStatus } =
        subscription || {};

      return subscription ? (
        <>
          <Form>
            <FormGroup className="mb-3">
              <Label for="userId">User</Label>
              <Select
                options={usersToSelectOptions(users)}
                onChange={option =>
                  onChangeSubscription({
                    userId: option.value,
                    userName: option.label,
                  })
                }
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <Label for="packageId">Package</Label>
              <Input
                id="packageId"
                name="packageId"
                type="select"
                value={packageId}
                onChange={e =>
                  onChangeSubscription({
                    packageId: Number(e.target.value),
                    duration: packages.find(
                      pack => pack.id === Number(e.target.value)
                    ).duration,
                    price: packages.find(
                      pack => pack.id === Number(e.target.value)
                    ).price,
                  })
                }
              >
                <option>Select Package</option>
                {packages &&
                  packages
                    .filter(pack => pack.available)
                    .map((pack, i) => (
                      <option value={pack.id} key={i}>
                        {pack.name}
                      </option>
                    ))}
              </Input>
            </FormGroup>
            <FormGroup className="mb-3">
              <Label for="duration">Duration in Days</Label>
              <Input
                id="duration"
                name="duration"
                placeholder="Enter duration..."
                type="text"
                value={duration}
                onChange={e =>
                  onChangeSubscription({ duration: Number(e.target.value) })
                }
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <Label for="price">Price</Label>
              <Input
                id="price"
                name="price"
                placeholder="Enter price..."
                type="text"
                value={price}
                onChange={e => onChangeSubscription({ price: e.target.value })}
              />
            </FormGroup>

            <FormGroup className="mb-3">
              <Label for="paymentStatus">Payment Status</Label>
              <Input
                id="paymentStatus"
                name="paymentStatus"
                type="select"
                value={paymentStatus}
                onChange={e =>
                  onChangeSubscription({
                    paymentStatus: e.target.value,
                  })
                }
              >
                <option>Select Status</option>
                {Object.values(PaymentStatuses)
                  .filter(value => typeof value === "number")
                  .map((key, i) => (
                    <option key={i}>{PaymentStatuses[key]}</option>
                  ))}
              </Input>
            </FormGroup>
          </Form>

          <Button
            color="primary"
            className="w-md me-2"
            onClick={onSaveSubscription}
            disabled={status.saving}
          >
            {status.saving ? (
              <i className="fas fa-circle-notch fa-spin"></i>
            ) : status.success ? (
              <i className="fas fa-check "></i>
            ) : (
              "Save"
            )}
          </Button>
          <Button
            color="light"
            className="w-md"
            onClick={onResetSubscription}
            disabled={status.saving}
          >
            Reset
          </Button>
        </>
      ) : (
        <p>Loading...</p>
      );
    },
    resourceName,
    getSourceFunc,
    setSourceFunc,
    selectorFunc,
    statusSelectorFunc
  );

export const SubscriptionCreate = ({ packages, users }) => {
  const Component = SubscriptionCreateForm(users, packages);
  return <Component />;
};

SubscriptionCreate.propTypes = {
  packages: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
};
