import {
  CreatePackageDTO,
  UpdatePackageDTO,
} from "core/entities/Package/PackageTypes";
import { Package } from "../../entities/Package/Package";
import { PackageRepository } from "../../entities/Package/PackageRepository";

export interface PackageService {
  GetPackage(id: number): Promise<Package>;
  GetPackages(available: boolean): Promise<Package[]>;
  CreatePackage(data: CreatePackageDTO): Promise<Package>;
  UpdatePackage(data: UpdatePackageDTO): Promise<Package>;
  DeletePackage(id: number): Promise<number>;
}

export class PackageServiceImpl implements PackageService {
  packageRepo: PackageRepository;

  constructor(ar: PackageRepository) {
    this.packageRepo = ar;
  }

  async GetPackage(id: number): Promise<Package> {
    return this.packageRepo.GetById(id);
  }

  async GetPackages(available: boolean): Promise<Package[]> {
    return this.packageRepo.GetAll(available);
  }

  async CreatePackage(data: CreatePackageDTO): Promise<Package> {
    return this.packageRepo.Create(data);
  }

  async UpdatePackage(data: UpdatePackageDTO): Promise<Package> {
    return this.packageRepo.Update(data);
  }

  async DeletePackage(id: number): Promise<number> {
    return this.packageRepo.Delete(id);
  }
}
