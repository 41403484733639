import React, { forwardRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateInput = forwardRef(({ value, onClick }, ref) => (
  <Input
    ref={ref}
    type="text"
    value={value}
    onClick={onClick}
    onChange={() => {}}
  />
));

DateInput.propTypes = {
  value: PropTypes.any,
  onClick: PropTypes.func,
};

DateInput.displayName = "DateInput";

export const CronLogFilters = ({ filters, onChange }) => {
  const [newFilters, setNewFilters] = useState({ ...filters });
  const { dateGte, dateLte } = newFilters;

  const handleOnChange = newFilter => {
    setNewFilters({
      ...newFilters,
      ...newFilter,
    });
  };

  const applyFilters = () => {
    onChange(newFilters);
  };

  return (
    <Card>
      <CardBody>
        <Row className="mt-2">
          <Col sm={3}>
            <FormGroup className="mb-2 me-sm-2 mb-sm-0">
              <Label className="me-sm-2" for="date">
                Date
              </Label>
              <Row>
                <Col sm={6}>
                  <DatePicker
                    selected={dateGte}
                    maxDate={dateLte}
                    dateFormat="dd/MM/yyyy"
                    isClearable
                    onChange={date => {
                      handleOnChange({
                        dateGte: date,
                      });
                    }}
                    customInput={<DateInput />}
                  />
                </Col>
                <Col sm={6}>
                  <DatePicker
                    selected={dateLte}
                    minDate={dateGte}
                    dateFormat="dd/MM/yyyy"
                    isClearable
                    onChange={date =>
                      handleOnChange({
                        dateLte: date,
                      })
                    }
                    customInput={<DateInput />}
                  />
                </Col>
              </Row>
            </FormGroup>
          </Col>
          <Col sm={3}>
            <Button
              color="primary"
              className="w-md"
              onClick={applyFilters}
              style={{ marginTop: 28 }}
            >
              Filter
            </Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

CronLogFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};
