import {
  GET_PACKAGE,
  GET_PACKAGE_FAIL,
  GET_PACKAGE_SUCCESS,
  UPDATE_PACKAGE,
  UPDATE_PACKAGE_FAIL,
  UPDATE_PACKAGE_SUCCESS,
  RESET_STATUS,
  GET_PACKAGES,
  GET_PACKAGES_SUCCESS,
  GET_PACKAGES_FAIL,
  CREATE_PACKAGE,
  CREATE_PACKAGE_SUCCESS,
  CREATE_PACKAGE_FAIL,
  DELETE_PACKAGE,
  DELETE_PACKAGE_SUCCESS,
  DELETE_PACKAGE_FAIL,
  RESET_STATE,
} from "./actionTypes";

export const getPackage = id => ({
  type: GET_PACKAGE,
  payload: id,
});

export const getPackageSuccess = pack => ({
  type: GET_PACKAGE_SUCCESS,
  payload: pack,
});

export const getPackageFail = error => ({
  type: GET_PACKAGE_FAIL,
  payload: error,
});

export const getPackages = () => ({
  type: GET_PACKAGES,
});

export const getPackagesSuccess = packages => ({
  type: GET_PACKAGES_SUCCESS,
  payload: packages,
});

export const getPackagesFail = error => ({
  type: GET_PACKAGES_FAIL,
  payload: error,
});

export const createPackage = data => ({
  type: CREATE_PACKAGE,
  payload: data,
});

export const createPackageSuccess = data => ({
  type: CREATE_PACKAGE_SUCCESS,
  payload: data,
});

export const createPackageFail = error => ({
  type: CREATE_PACKAGE_FAIL,
  payload: error,
});
export const updatePackage = data => ({
  type: UPDATE_PACKAGE,
  payload: data,
});

export const updatePackageSuccess = data => ({
  type: UPDATE_PACKAGE_SUCCESS,
  payload: data,
});

export const updatePackageFail = error => ({
  type: UPDATE_PACKAGE_FAIL,
  payload: error,
});

export const deletePackage = id => ({
  type: DELETE_PACKAGE,
  payload: id,
});

export const deletePackageSuccess = data => ({
  type: DELETE_PACKAGE_SUCCESS,
  payload: data,
});

export const deletePackageFail = error => ({
  type: DELETE_PACKAGE_FAIL,
  payload: error,
});

export const resetState = () => ({
  type: RESET_STATE,
});

export const resetStatus = () => ({
  type: RESET_STATUS,
});
