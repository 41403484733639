export const EXCHANGE_CODE = "EXCHANGE_CODE";
export const EXCHANGE_CODE_SUCCESS = "EXCHANGE_CODE_SUCCESS";
export const EXCHANGE_CODE_FAIL = "EXCHANGE_CODE_FAIL";

export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_FAIL = "REFRESH_TOKEN_FAIL";

export const REVOKE_TOKEN = "REVOKE_TOKEN";
export const REVOKE_TOKEN_SUCCESS = "REVOKE_TOKEN_SUCCESS";
export const REVOKE_TOKEN_FAIL = "REVOKE_TOKEN_FAIL";

export const LOGOUT = "LOGOUT";
