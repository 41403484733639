import {
  GET_WALLET,
  GET_WALLET_FAIL,
  GET_WALLET_SUCCESS,
  UPDATE_WALLET,
  UPDATE_WALLET_FAIL,
  UPDATE_WALLET_SUCCESS,
  RESET_STATUS,
  GET_WALLETS,
  GET_WALLETS_SUCCESS,
  GET_WALLETS_FAIL,
  CREATE_WALLET,
  CREATE_WALLET_SUCCESS,
  CREATE_WALLET_FAIL,
  DELETE_WALLET,
  DELETE_WALLET_SUCCESS,
  DELETE_WALLET_FAIL,
  RESET_STATE,
  GET_WALLET_BALANCE,
  GET_WALLET_BALANCE_SUCCESS,
  GET_WALLET_BALANCE_FAIL,
} from "./actionTypes";

export const getWallet = id => ({
  type: GET_WALLET,
  payload: id,
});

export const getWalletSuccess = wallet => ({
  type: GET_WALLET_SUCCESS,
  payload: wallet,
});

export const getWalletFail = error => ({
  type: GET_WALLET_FAIL,
  payload: error,
});

export const getWalletBalance = id => ({
  type: GET_WALLET_BALANCE,
  payload: id,
});

export const getWalletBalanceSuccess = walletBalance => ({
  type: GET_WALLET_BALANCE_SUCCESS,
  payload: walletBalance,
});

export const getWalletBalanceFail = error => ({
  type: GET_WALLET_BALANCE_FAIL,
  payload: error,
});

export const getWallets = () => ({
  type: GET_WALLETS,
});

export const getWalletsSuccess = wallets => ({
  type: GET_WALLETS_SUCCESS,
  payload: wallets,
});

export const getWalletsFail = error => ({
  type: GET_WALLETS_FAIL,
  payload: error,
});

export const createWallet = data => ({
  type: CREATE_WALLET,
  payload: data,
});

export const createWalletSuccess = data => ({
  type: CREATE_WALLET_SUCCESS,
  payload: data,
});

export const createWalletFail = error => ({
  type: CREATE_WALLET_FAIL,
  payload: error,
});
export const updateWallet = data => ({
  type: UPDATE_WALLET,
  payload: data,
});

export const updateWalletSuccess = data => ({
  type: UPDATE_WALLET_SUCCESS,
  payload: data,
});

export const updateWalletFail = error => ({
  type: UPDATE_WALLET_FAIL,
  payload: error,
});

export const deleteWallet = id => ({
  type: DELETE_WALLET,
  payload: id,
});

export const deleteWalletSuccess = data => ({
  type: DELETE_WALLET_SUCCESS,
  payload: data,
});

export const deleteWalletFail = error => ({
  type: DELETE_WALLET_FAIL,
  payload: error,
});

export const resetState = () => ({
  type: RESET_STATE,
});

export const resetStatus = () => ({
  type: RESET_STATUS,
});
