import {
  RESET_STATUS,
  CREATE_PAYMENT,
  CREATE_PAYMENT_SUCCESS,
  CREATE_PAYMENT_FAIL,
  RESET_STATE,
  GET_PAYMENT_STATUS,
  GET_PAYMENT_STATUS_SUCCESS,
  GET_PAYMENT_STATUS_FAIL,
  CANCEL_PAYMENT,
  CANCEL_PAYMENT_SUCCESS,
  CANCEL_PAYMENT_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  payment: null,
  loading: false,
  saving: false,
  success: false,
  error: {},
};

const PaymentState = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PAYMENT_STATUS:
      return {
        ...state,
        loading: true,
        error: {},
      };

    case GET_PAYMENT_STATUS_SUCCESS:
      return {
        ...state,
        payment: { ...state.payment, ...action.payload },
        loading: false,
        error: {},
      };

    case GET_PAYMENT_STATUS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case CREATE_PAYMENT:
      return {
        ...state,
        saving: true,
        success: false,
        error: {},
      };

    case CREATE_PAYMENT_SUCCESS:
      return {
        ...state,
        payment: action.payload,
        saving: false,
        success: true,
        error: {},
      };

    case CREATE_PAYMENT_FAIL:
      return {
        ...state,
        error: action.payload,
        saving: false,
        success: false,
      };

    case CANCEL_PAYMENT:
      return {
        ...state,
        saving: true,
        success: false,
        error: {},
      };

    case CANCEL_PAYMENT_SUCCESS:
      return {
        ...state,
        payment: action.payload,
        saving: false,
        success: true,
        error: {},
      };

    case CANCEL_PAYMENT_FAIL:
      return {
        ...state,
        error: action.payload,
        saving: false,
        success: false,
      };

    case RESET_STATE:
      return {
        ...state,
        payment: null,
      };

    case RESET_STATUS:
      return {
        ...state,
        error: {},
        saving: false,
        success: false,
        loading: false,
      };

    default:
      return state;
  }
};

export default PaymentState;
