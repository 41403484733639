import { client } from "../../HttpClient";
import { GET_USER, GET_USERS } from "./graphql";

export const getUser = async (id: number): Promise<any> => {
  const response = await client.query({
    query: GET_USER,
    variables: { id },
  });
  return response.data.user;
};

export const getUsers = async (limit: number, after: string): Promise<any> => {
  const response = await client.query({
    query: GET_USERS,
    variables: { limit, after },
  });
  return response.data.users;
};
