import { put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import {
  CANCEL_PAYMENT,
  CREATE_PAYMENT,
  GET_PAYMENT_STATUS,
} from "./actionTypes";
import {
  createPaymentSuccess,
  createPaymentFail,
  resetStatus,
  getPaymentStatusFail,
  cancelPaymentSuccess,
  cancelPaymentFail,
  getPaymentStatusSuccess,
} from "./actions";

import { PaymentRepositoryImpl } from "core/infrastructure/Payment/PaymentRepositoryImpl";
import { PaymentServiceImpl } from "core/usecases/Payment/PaymentService";

function* fetchPaymentStatus({ payload }) {
  try {
    const PaymentRepo = new PaymentRepositoryImpl();
    const PaymentService = new PaymentServiceImpl(PaymentRepo);
    const response = yield PaymentService.GetPaymentStatus(payload);
    yield put(getPaymentStatusSuccess(response));
  } catch (error) {
    yield put(getPaymentStatusFail(error));
  }
}

function* createPayment({ payload }) {
  try {
    const PaymentRepo = new PaymentRepositoryImpl();
    const PaymentService = new PaymentServiceImpl(PaymentRepo);
    const response = yield PaymentService.CreatePayment(payload);
    yield put(createPaymentSuccess(response));
    yield put(resetStatus(response));
  } catch (error) {
    yield put(createPaymentFail(error));
  }
}

function* cancelPayment({ payload }) {
  try {
    const PaymentRepo = new PaymentRepositoryImpl();
    const PaymentService = new PaymentServiceImpl(PaymentRepo);
    const response = yield PaymentService.CancelPayment(payload);
    yield put(cancelPaymentSuccess(response));
    yield put(resetStatus(response));
  } catch (error) {
    yield put(cancelPaymentFail(error));
  }
}

function* PaymentSaga() {
  yield takeEvery(GET_PAYMENT_STATUS, fetchPaymentStatus);
  yield takeEvery(CREATE_PAYMENT, createPayment);
  yield takeEvery(CANCEL_PAYMENT, cancelPayment);
}

export default PaymentSaga;
