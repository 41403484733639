import React from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Button,
} from "reactstrap";

import SweetAlert from "react-bootstrap-sweetalert";
import PaymentBox from "./paymentBox";

import { useDispatch, useSelector } from "react-redux";
import { createPayment, resetStatus } from "store/actions";

const Payment = props => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.Auth.auth);
  const { payment, error, saving } = useSelector(state => state.PaymentState);

  const handleCreatePayment = () => {
    const { id, extendSubscriptionId } = props.match.params;
    const data = {
      userId: user.userId,
      userName: user.userName,
      packageId: parseInt(id),
      extendSubscriptionId: extendSubscriptionId
        ? parseInt(extendSubscriptionId)
        : null,
    };
    dispatch(createPayment(data));
  };

  const handleResetStatus = () => {
    dispatch(resetStatus());
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Ödeme</title>
        </MetaTags>
        <Container fluid>
          <Row className="justify-content-center mt-3">
            <Col lg={6}>
              <div className="text-center mb-5">
                <h3>Ödeme</h3>
                <p className="fs-5 lh-base  mt-3">
                  Ödemenizi sistemimizin sizin için oluşturacağı{" "}
                  <span className="fw-bold text-primary">USDT</span> cüzdanınıza
                  göndermeniz gerekmektedir.
                </p>
                <p className="fs-5 lh-base  ">
                  Ödemeyi{" "}
                  <span className="fw-bold text-primary">TRC20 (Tron)</span>{" "}
                  ağını kullanarak yapmalısınız!
                </p>
                <p className="fs-5 lh-base  mb-5">
                  Ödeme işlemi tamamlanana kadar bu{" "}
                  <span className="fw-bold text-primary">
                    sayfayı kapatmayın ya da sayfayı yenilemeyin!
                  </span>
                </p>

                {payment === null && !saving && (
                  <Button
                    color="primary"
                    size="lg"
                    onClick={handleCreatePayment}
                  >
                    Ödeme Cüzdanı Oluştur
                  </Button>
                )}
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center mt-3 mb-5">
            <Col style={{ maxWidth: "890px" }}>
              <Card className="plan-box">
                <CardBody className="p-0 px-4">
                  {payment && !saving && <PaymentBox />}
                  {saving && (
                    <div className="text-center p-5">
                      <Spinner color="primary" />
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <SweetAlert
            danger
            title={error?.message || ""}
            onConfirm={handleResetStatus}
            show={typeof error.message !== "undefined"}
          ></SweetAlert>
        </Container>
      </div>
    </React.Fragment>
  );
};

Payment.propTypes = {
  match: PropTypes.object,
};

export default withRouter(Payment);
