import {
  GET_CRON_LOGS,
  GET_CRON_LOGS_SUCCESS,
  GET_CRON_LOGS_FAIL,
} from "./actionTypes";

export const getCronLogs = (skip, first, dateGte, dateLte) => ({
  type: GET_CRON_LOGS,
  payload: {
    skip,
    first,
    dateGte,
    dateLte,
  },
});

export const getCronLogsSuccess = cronLogs => ({
  type: GET_CRON_LOGS_SUCCESS,
  payload: cronLogs,
});

export const getCronLogsFail = error => ({
  type: GET_CRON_LOGS_FAIL,
  payload: error,
});
