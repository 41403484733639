import { put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import {
  CREATE_WALLET,
  DELETE_WALLET,
  GET_WALLET,
  GET_WALLETS,
  GET_WALLET_BALANCE,
  UPDATE_WALLET,
} from "./actionTypes";
import {
  getWalletSuccess,
  getWalletFail,
  getWalletsSuccess,
  getWalletsFail,
  createWalletSuccess,
  createWalletFail,
  updateWalletSuccess,
  updateWalletFail,
  deleteWalletSuccess,
  deleteWalletFail,
  resetStatus,
  resetState,
  getWalletBalanceSuccess,
  getWalletBalanceFail,
} from "./actions";

import { WalletRepositoryImpl } from "core/infrastructure/Wallet/WalletRepositoryImpl";
import { WalletServiceImpl } from "core/usecases/Wallet/WalletService";

function delay(ms) {
  return new Promise(resolve => setTimeout(() => resolve(true), ms));
}

function* fetchWallet({ payload }) {
  try {
    const WalletRepo = new WalletRepositoryImpl();
    const WalletService = new WalletServiceImpl(WalletRepo);
    const response = yield WalletService.GetWallet(payload);
    yield put(getWalletSuccess(response));
  } catch (error) {
    yield put(getWalletFail(error));
  }
}

function* fetchWalletBalance({ payload }) {
  try {
    const WalletRepo = new WalletRepositoryImpl();
    const WalletService = new WalletServiceImpl(WalletRepo);
    const response = yield WalletService.GetWalletBalance(payload);
    yield put(getWalletBalanceSuccess(response));
  } catch (error) {
    yield put(getWalletBalanceFail(error));
  }
}

function* fetchWallets() {
  try {
    const WalletRepo = new WalletRepositoryImpl();
    const WalletService = new WalletServiceImpl(WalletRepo);
    const response = yield WalletService.GetWallets();
    yield put(getWalletsSuccess(response));
  } catch (error) {
    yield put(getWalletsFail(error));
  }
}

function* createWallet({ payload }) {
  try {
    const WalletRepo = new WalletRepositoryImpl();
    const WalletService = new WalletServiceImpl(WalletRepo);
    const response = yield WalletService.CreateWallet(payload);
    yield put(createWalletSuccess(response));
    yield put(resetState(response));
    yield delay(3000);
    yield put(resetStatus(response));
  } catch (error) {
    yield put(createWalletFail(error));
  }
}

function* updateWallet({ payload }) {
  try {
    const WalletRepo = new WalletRepositoryImpl();
    const WalletService = new WalletServiceImpl(WalletRepo);
    const response = yield WalletService.UpdateWallet(payload);
    yield put(updateWalletSuccess(response));
    yield delay(3000);
    yield put(resetStatus(response));
  } catch (error) {
    yield put(updateWalletFail(error));
  }
}

function* deleteWallet({ payload }) {
  try {
    const WalletRepo = new WalletRepositoryImpl();
    const WalletService = new WalletServiceImpl(WalletRepo);
    const response = yield WalletService.DeleteWallet(payload);
    yield put(deleteWalletSuccess(response));
    yield delay(3000);
    yield put(resetStatus());
  } catch (error) {
    yield put(deleteWalletFail(error));
  }
}

function* WalletSaga() {
  yield takeEvery(GET_WALLET, fetchWallet);
  yield takeEvery(GET_WALLET_BALANCE, fetchWalletBalance);
  yield takeEvery(GET_WALLETS, fetchWallets);
  yield takeEvery(CREATE_WALLET, createWallet);
  yield takeEvery(UPDATE_WALLET, updateWallet);
  yield takeEvery(DELETE_WALLET, deleteWallet);
}

export default WalletSaga;
