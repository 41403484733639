import {
  CreatePackageDTO,
  PackageDTO,
  UpdatePackageDTO,
} from "core/entities/Package/PackageTypes";
import { serializeDTO } from "helpers/utils";
import { Package } from "../../entities/Package/Package";
import { PackageRepository } from "../../entities/Package/PackageRepository";
import services from "../Services";

export class PackageRepositoryImpl implements PackageRepository {
  async GetById(id: number): Promise<Package> {
    const item: PackageDTO = await services.api.getPackage(id);
    return new Package(
      item.id,
      item.name,
      item.description,
      item.duration,
      item.price,
      item.roleId,
      item.roleName,
      item.available,
      item.listOrder
    );
  }
  async GetAll(available: boolean): Promise<Package[]> {
    const items = await services.api.getPackages(available);
    return items.map(
      (item: PackageDTO) =>
        new Package(
          item.id,
          item.name,
          item.description,
          item.duration,
          item.price,
          item.roleId,
          item.roleName,
          item.available,
          item.listOrder
        )
    );
  }
  async Create(data: CreatePackageDTO): Promise<Package> {
    const serializedData = serializeDTO(CreatePackageDTO, data);

    const item: PackageDTO = await services.api.createPackage(serializedData);
    return new Package(
      item.id,
      item.name,
      item.description,
      item.duration,
      item.price,
      item.roleId,
      item.roleName,
      item.available,
      item.listOrder
    );
  }

  async Update(data: UpdatePackageDTO): Promise<Package> {
    const serializedData = serializeDTO(UpdatePackageDTO, data);

    const item: PackageDTO = await services.api.updatePackage(serializedData);
    return new Package(
      item.id,
      item.name,
      item.description,
      item.duration,
      item.price,
      item.roleId,
      item.roleName,
      item.available,
      item.listOrder
    );
  }

  async Delete(id: number): Promise<number> {
    const item: PackageDTO = await services.api.deletePackage(id);
    return id;
  }
}
