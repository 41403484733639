import {
  CreateSubscriptionDTO,
  PaymentStatuses,
  SubscriptionDTO,
  SubscriptionListType,
  UpdateSubscriptionDTO,
} from "core/entities/Subscription/SubscriptionTypes";
import { serializeDTO } from "helpers/utils";
import { Subscription } from "../../entities/Subscription/Subscription";
import { SubscriptionRepository } from "../../entities/Subscription/SubscriptionRepository";
import services from "../Services";

export class SubscriptionRepositoryImpl implements SubscriptionRepository {
  async GetById(id: number): Promise<Subscription> {
    const item: SubscriptionDTO = await services.api.getSubscription(id);
    return new Subscription(
      item.id,
      item.date,
      item.userId,
      item.userName,
      item.package,
      item.duration,
      item.price,
      item.expireDate,
      item.paymentStatus
    );
  }

  async GetAll(
    skip: number,
    first: number,
    userId: string,
    packageId: number,
    paymentStatus: PaymentStatuses,
    price: string,
    dateGte: string,
    dateLte: string
  ): Promise<SubscriptionListType> {
    const response: SubscriptionListType = await services.api.getSubscriptions(
      skip,
      first,
      userId,
      packageId,
      paymentStatus,
      price,
      dateGte,
      dateLte
    );
    return response;
  }

  async Create(data: CreateSubscriptionDTO): Promise<Subscription> {
    const serializedData = serializeDTO(CreateSubscriptionDTO, data);

    const item: SubscriptionDTO = await services.api.createSubscription(
      serializedData
    );
    return new Subscription(
      item.id,
      item.date,
      item.userId,
      item.userName,
      item.package,
      item.duration,
      item.price,
      item.expireDate,
      item.paymentStatus
    );
  }

  async Update(data: UpdateSubscriptionDTO): Promise<Subscription> {
    const serializedData = serializeDTO(UpdateSubscriptionDTO, data);
    const item: SubscriptionDTO = await services.api.updateSubscription(
      serializedData
    );
    return new Subscription(
      item.id,
      item.date,
      item.userId,
      item.userName,
      item.package,
      item.duration,
      item.price,
      item.expireDate,
      item.paymentStatus
    );
  }

  async Delete(id: number): Promise<Subscription> {
    const item: SubscriptionDTO = await services.api.deleteSubscription(id);
    return new Subscription(
      item.id,
      item.date,
      item.userId,
      item.userName,
      item.package,
      item.duration,
      item.price,
      item.expireDate,
      item.paymentStatus
    );
  }
}
