import * as AuthService from "./auth";
import * as PackageService from "./package";
import * as PaymentGatewaySettingsService from "./payment-gateway-settings";
import * as WalletService from "./wallet";
import * as RoleService from "./role";
import * as SubscriptionService from "./subscription";
import * as UserService from "./user";
import * as PaymentService from "./payment";
import * as CronLogService from "./cron-logs";

const apiService = {
  ...AuthService,
  ...PackageService,
  ...PaymentGatewaySettingsService,
  ...WalletService,
  ...RoleService,
  ...SubscriptionService,
  ...UserService,
  ...PaymentService,
  ...CronLogService,
};

export default apiService;
