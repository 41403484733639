import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { SubscriptionFilters } from "./subscriptionFilters";
import { SimpleTable } from "components/SimpleTable";
import { MyPagination } from "components/Pagination";
import { endDate, startDate } from "helpers/utils";
import moment from "moment";
import { getSubscriptions } from "store/actions";
import { Card, CardBody } from "reactstrap";
import { useDataSource } from "hooks/useDataSource";
import Loading from "components/Loading/Index";

const tableColumns = [
  {
    key: "date",
    value: "Date",
    formatter: (row, value) => moment(value).format("DD.MM.YYYY HH:mm"),
  },
  {
    key: "userName",
    value: "User",
  },
  {
    key: "package",
    value: "Package",
    formatter: (row, value) => value.name,
  },
  {
    key: "price",
    value: "Price",
  },
  {
    key: "duration",
    value: "Duration",
  },
  {
    key: "expireDate",
    value: "Expire Date",
    formatter: (row, value) => moment(value).format("DD.MM.YYYY HH:mm"),
  },
  {
    key: "paymentStatus",
    value: "Payment Status",
  },
];

export const SubscriptionList = ({ packages, onRowClick }) => {
  const dispatch = useDispatch();
  const { subscriptions, loading } = useDataSource(
    () =>
      getSubscriptions(
        pagination.skip,
        pagination.first,
        filters.userId,
        filters.packageId,
        filters.paymentStatus,
        filters.price,
        filters.dateGte,
        filters.dateLte
      ),
    state => state.SubscriptionState
  );
  const [pagination, setPagination] = useState({
    skip: 0,
    first: 50,
  });
  const [filters, setFilters] = useState({
    userId: null,
    packageId: null,
    paymentStatus: null,
    price: null,
    dateGte: null,
    dateLte: null,
  });

  useEffect(() => {
    dispatch(
      getSubscriptions(
        pagination.skip,
        pagination.first,
        filters.userId,
        filters.packageId,
        filters.paymentStatus,
        filters.price,
        startDate(filters.dateGte),
        endDate(filters.dateLte)
      )
    );

    return () => {};
  }, [pagination, filters]);

  return (
    <>
      <SubscriptionFilters
        onChange={setFilters}
        filters={filters}
        packages={packages}
      />

      <Card>
        <CardBody>
          {subscriptions?.results && (
            <>
              <SimpleTable
                columns={tableColumns}
                rows={subscriptions.results}
                onRowClick={onRowClick}
              />
              <MyPagination
                skip={pagination.skip}
                first={pagination.first}
                totalCount={subscriptions.totalCount}
                onChange={setPagination}
              />
            </>
          )}
          {loading && <Loading />}
        </CardBody>
      </Card>
    </>
  );
};

SubscriptionList.propTypes = {
  packages: PropTypes.array.isRequired,
  onRowClick: PropTypes.func,
};
