import { CronLogListType } from "core/entities/CronLog/CronLogTypes";
import { CronLogRepository } from "core/entities/CronLog/CronLogRepository";

export interface CronLogService {
  GetCronLogs(
    skip: number,
    first: number,
    dateGte: string,
    dateLte: string
  ): Promise<CronLogListType>;
}

export class CronLogServiceImpl implements CronLogService {
  packageRepo: CronLogRepository;

  constructor(ar: CronLogRepository) {
    this.packageRepo = ar;
  }

  async GetCronLogs(
    skip: number,
    first: number,
    dateGte: string,
    dateLte: string
  ): Promise<CronLogListType> {
    return this.packageRepo.GetAll(skip, first, dateGte, dateLte);
  }
}
