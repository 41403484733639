import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Media } from "reactstrap";
import DOMPurify from "dompurify";
import { durationText } from "../../helpers/utils";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";

const SubscribeButton = ({ activeSubs, id }) => {
  const activeSub = activeSubs?.find(sub => sub.packageId === id);
  if (activeSub) {
    return (
      <NavLink
        to={`payment/${id}/${activeSub.subscriptionId}`}
        className="btn btn-outline-primary btn-lg px-5"
      >
        Uzat
      </NavLink>
    );
  }

  return (
    <NavLink
      to={`payment/${id}`}
      className="btn btn-outline-primary btn-lg px-5"
    >
      Satın Al
    </NavLink>
  );
};

SubscribeButton.propTypes = {
  activeSubs: PropTypes.array.isRequired,
  id: PropTypes.number.isRequired,
};

export const SubscriptionPlan = ({ plan }) => {
  const { id, name, description, duration, price } = plan || {};
  const activeSubs = useSelector(state =>
    state.SubscriptionState.mySubscriptions?.results
      ?.filter(
        sub =>
          sub.paymentStatus === "COMPLETED" &&
          moment(sub.expireDate) >= moment()
      )
      ?.map(sub => {
        return { subscriptionId: sub.id, packageId: sub.package.id };
      })
  );

  return (
    <Card className="plan-box text-center py-4" key={id}>
      <CardBody className="p-4">
        <Media>
          <Media body>
            <h5 className="text-uppercase">{name}</h5>
            <h1 className="mt-4">
              <sup>
                <small>$</small>
              </sup>{" "}
              <span>{price}</span>
              <span className="font-size-13">/ {durationText(duration)}</span>
            </h1>
          </Media>
        </Media>
        <div className="py-4 details">
          <p
            className="text-muted mb-1"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(description, {
                ADD_ATTR: ["target"],
              }),
            }}
          ></p>
        </div>
        <div className="text-center">
          <SubscribeButton activeSubs={activeSubs} id={id} />
        </div>
      </CardBody>
    </Card>
  );
};

SubscriptionPlan.propTypes = {
  plan: PropTypes.object.isRequired,
};
