import React from "react";
import PropTypes from "prop-types";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { createPackage, getRoles } from "store/actions";
import { DefaultEditor } from "react-simple-wysiwyg";
import { withCreatableResource } from "hocs/withCreatableResource";
import { useDataSource } from "hooks/useDataSource";

const setSourceFunc = createPackage;
const resourceName = "pack";
const statusSelectorFunc = state => {
  return {
    saving: state.PackageState.saving,
    success: state.PackageState.success,
    error: state.PackageState.error,
  };
};

const PackageCreateForm = roles =>
  withCreatableResource(
    ({ pack, status, onChangePack, onSavePack }) => {
      const {
        name,
        description,
        roleId,
        price,
        duration,
        available,
        listOrder,
      } = pack || {};

      return (
        <>
          <Form>
            <FormGroup className="mb-3">
              <Label for="name">Name</Label>
              <Input
                id="name"
                name="name"
                placeholder="Enter package name..."
                type="text"
                value={name}
                onChange={e => onChangePack({ name: e.target.value })}
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <Label for="description">Description</Label>
              <DefaultEditor
                value={description}
                onChange={e => onChangePack({ description: e.target.value })}
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <Label for="roleId">Role</Label>
              <Input
                id="roleId"
                name="roleId"
                type="select"
                value={roleId}
                onChange={e =>
                  onChangePack({
                    roleId: e.target.value,
                    roleName: roles.find(role => role.id === e.target.value)
                      .name,
                  })
                }
              >
                <option>Select Role</option>
                {roles.map((role, i) => (
                  <option value={role.id} key={i}>
                    {role.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup className="mb-3">
              <Label for="duration">Duration in Days</Label>
              <Input
                id="duration"
                name="duration"
                placeholder="Enter duration..."
                type="text"
                value={duration}
                onChange={e =>
                  onChangePack({ duration: Number(e.target.value) })
                }
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <Label for="price">Price</Label>
              <Input
                id="price"
                name="price"
                placeholder="Enter price..."
                type="text"
                value={price}
                onChange={e => onChangePack({ price: e.target.value })}
              />
            </FormGroup>

            <div className="form-check form-switch form-switch-md mb-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="available"
                checked={available}
                value={true}
                onChange={e => onChangePack({ available: e.target.checked })}
              />
              <label className="form-check-label" htmlFor="available">
                Is Available to Sell?
              </label>
            </div>

            <FormGroup className="mb-3">
              <Label for="listOrder">List Order</Label>
              <Input
                id="listOrder"
                name="listOrder"
                placeholder="Enter list order..."
                type="text"
                value={listOrder}
                onChange={e =>
                  onChangePack({ listOrder: Number(e.target.value) })
                }
              />
            </FormGroup>
          </Form>

          <Button
            color="primary"
            className="w-md me-2"
            onClick={onSavePack}
            disabled={status.saving}
          >
            {status.saving ? (
              <i className="fas fa-circle-notch fa-spin"></i>
            ) : status.success ? (
              <i className="fas fa-check "></i>
            ) : (
              "Save"
            )}
          </Button>
        </>
      );
    },
    resourceName,
    setSourceFunc,
    statusSelectorFunc
  );

export const PackageCreate = () => {
  const roles = useDataSource(getRoles, state => state.RoleState.roles);

  const Component = PackageCreateForm(roles);
  return <Component />;
};

PackageCreate.propTypes = {};
