import { PaymentStatuses } from "core/entities/Subscription/SubscriptionTypes";
import { client } from "../../HttpClient";
import {
  GET_SUBSCRIPTION,
  GET_SUBSCRIPTIONS,
  CREATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
  DELETE_SUBSCRIPTION,
} from "./graphql";

export const getSubscription = async (id: number): Promise<any> => {
  const response = await client.query({
    query: GET_SUBSCRIPTION,
    variables: { id },
    fetchPolicy: "network-only",
  });
  return response.data.subscription;
};

export const getSubscriptions = async (
  skip: number,
  first: number,
  userId: string,
  packageId: number,
  paymentStatus: PaymentStatuses,
  price: string,
  dateGte: string,
  dateLte: string
): Promise<any> => {
  const response = await client.query({
    query: GET_SUBSCRIPTIONS,
    variables: {
      skip,
      first,
      userId,
      packageId,
      paymentStatus,
      price,
      dateGte,
      dateLte,
    },
    fetchPolicy: "network-only",
  });
  return response.data.subscriptions;
};

export const createSubscription = async (data: object): Promise<any> => {
  const response = await client.mutate({
    mutation: CREATE_SUBSCRIPTION,
    variables: {
      data: data,
    },
  });
  return response.data.createSubscription.subscription;
};

export const updateSubscription = async (data: object): Promise<any> => {
  const response = await client.mutate({
    mutation: UPDATE_SUBSCRIPTION,
    variables: {
      data: data,
    },
  });
  return response.data.updateSubscription.subscription;
};

export const deleteSubscription = async (id: number): Promise<any> => {
  const response = await client.mutate({
    mutation: DELETE_SUBSCRIPTION,
    variables: {
      id,
    },
  });
  return response.data.deleteSubscription.subscription;
};
