import React from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import {
  getPaymentGatewaySettings,
  updatePaymentGatewaySettings,
} from "store/actions";
import { withEditableResource } from "../../hocs/withEditableResource";

const getSourceFunc = getPaymentGatewaySettings;
const setSourceFunc = updatePaymentGatewaySettings;
const selectorFunc = state => state.PaymentGatewayState.settings;
const resourceName = "settings";
const statusSelectorFunc = state => {
  return {
    saving: state.PaymentGatewayState.saving,
    success: state.PaymentGatewayState.success,
    error: state.PaymentGatewayState.error,
  };
};

export const SettingsForm = withEditableResource(
  ({ settings, status, onChangeSettings, onSaveSettings, onResetSettings }) => {
    const {
      withdrawPeriod,
      withdrawTriggerQuota,
      responseWebhookUrl,
      isActive,
    } = settings || {};

    return settings ? (
      <>
        <Form>
          <FormGroup className="mb-3">
            <Label for="withdrawPeriod">Withdraw Period</Label>
            <Input
              id="withdrawPeriod"
              name="withdrawPeriod"
              type="select"
              value={withdrawPeriod}
              onChange={e =>
                onChangeSettings({ withdrawPeriod: e.target.value })
              }
            >
              <option>INSTANTLY</option>
              <option>TRIGGER_QUOTA</option>
            </Input>
          </FormGroup>
          <FormGroup className="mb-3">
            <Label for="withdrawTriggerQuota">Withdraw Trigger Quota</Label>
            <Input
              id="withdrawTriggerQuota"
              name="withdrawTriggerQuota"
              placeholder="Enter withdraw trigger quota..."
              type="text"
              value={withdrawTriggerQuota}
              onChange={e =>
                onChangeSettings({
                  withdrawTriggerQuota: Number(e.target.value),
                })
              }
            />
          </FormGroup>
          <FormGroup className="mb-3">
            <Label for="responseWebhookUrl">Response Webhook URL</Label>
            <Input
              id="responseWebhookUrl"
              name="responseWebhookUrl"
              placeholder="Enter response webhook url..."
              type="text"
              value={responseWebhookUrl}
              onChange={e =>
                onChangeSettings({ responseWebhookUrl: e.target.value })
              }
            />
          </FormGroup>

          <div className="form-check form-switch form-switch-md mb-3">
            <input
              type="checkbox"
              className="form-check-input"
              id="isActive"
              checked={isActive}
              readOnly
            />
            <label className="form-check-label" htmlFor="isActive">
              Is Active?
            </label>
          </div>
        </Form>

        <Button
          color="primary"
          className="w-md me-2"
          onClick={onSaveSettings}
          disabled={status.saving}
        >
          {status.saving ? (
            <i className="fas fa-circle-notch fa-spin"></i>
          ) : status.success ? (
            <i className="fas fa-check "></i>
          ) : (
            "Save"
          )}
        </Button>
        <Button
          color="light"
          className="w-md"
          onClick={onResetSettings}
          disabled={status.saving}
        >
          Reset
        </Button>
      </>
    ) : (
      <p>Loading...</p>
    );
  },
  resourceName,
  getSourceFunc,
  setSourceFunc,
  selectorFunc,
  statusSelectorFunc
);
