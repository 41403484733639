import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { EXCHANGE_CODE, REFRESH_TOKEN, REVOKE_TOKEN } from "./actionTypes";
import {
  exchangeCodeSuccess,
  exchangeCodeFail,
  refreshTokenSuccess,
  refreshTokenFail,
  revokeTokenSuccess,
  revokeTokenFail,
} from "./actions";

import { AuthRepositoryImpl } from "core/infrastructure/Auth/AuthRepositoryImpl";
import { AuthServiceImpl } from "core/usecases/Auth/AuthService";

function* exchangeCode({ payload }) {
  const { code } = payload;
  try {
    const authRepo = new AuthRepositoryImpl();
    const authService = new AuthServiceImpl(authRepo);
    const response = yield authService.ExchangeCode(code);
    yield put(exchangeCodeSuccess(response));
  } catch (error) {
    yield put(exchangeCodeFail(error));
  }
}

function* refreshToken({ payload }) {
  const { refreshToken } = payload;
  try {
    const authRepo = new AuthRepositoryImpl();
    const authService = new AuthServiceImpl(authRepo);
    const response = yield authService.RefreshToken(refreshToken);
    yield put(refreshTokenSuccess(response));
  } catch (error) {
    yield put(refreshTokenFail(error));
  }
}

function* revokeToken({ payload }) {
  const { token } = payload;
  try {
    const authRepo = new AuthRepositoryImpl();
    const authService = new AuthServiceImpl(authRepo);
    const response = yield authService.RevokeToken(token);
    yield put(revokeTokenSuccess(response));
  } catch (error) {
    yield put(revokeTokenFail(error));
  }
}

function* authSaga() {
  yield takeEvery(EXCHANGE_CODE, exchangeCode);
  yield takeEvery(REFRESH_TOKEN, refreshToken);
  yield takeEvery(REVOKE_TOKEN, revokeToken);
}

export default authSaga;
