import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import Redirect from "../components/Redirect/Index";

// const loginUrl =
//   "https://discord.com/api/oauth2/authorize?response_type=code&client_id=986750336008077352&scope=identify%20guilds.members.read&state=15773059ghq9183habn&redirect_uri=http%3A%2F%2Fmember.sarperonder.com/exchange-code&prompt=consent";
const loginUrl =
  "https://discord.com/api/oauth2/authorize?client_id=986750336008077352&redirect_uri=http%3A%2F%2Fmember.sarperonder.com%2Fexchange-code&response_type=code&scope=identify%20guilds.members.read";
const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  isAdminProtected,
  ...rest
}) => {
  const { auth } = useSelector(state => state.Auth);
  return (
    <Route
      {...rest}
      render={props => {
        if (
          isAuthProtected &&
          isAdminProtected &&
          !!auth.accessToken &&
          auth.user.role !== "ADMIN"
        ) {
          return <Redirect to={"/"} />;
        }

        if (isAuthProtected && !auth.accessToken) {
          return <Redirect to={loginUrl} />;
        }

        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

AppRoute.propTypes = {
  isAuthProtected: PropTypes.bool,
  isAdminProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
};

export default AppRoute;
