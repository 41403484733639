import React from "react";
import MetaTags from "react-meta-tags";
import { useDataSource } from "hooks/useDataSource";
import { Container } from "reactstrap";
import { getCronLogs } from "store/actions";
import { CronLogList } from "./cronLogList";

const CronLogs = () => {
  const cronLogs = useDataSource(
    getCronLogs,
    state => state.PackageState.cronLogs
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Logs</title>
        </MetaTags>
        <Container fluid>
          <h4 className="mb-3">Logs</h4>
        </Container>

        <CronLogList cronLogs={cronLogs} />
      </div>
    </React.Fragment>
  );
};

export default CronLogs;
