import React from "react";
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetState } from "store/actions";

const PaymentResult = ({ paymentStatus, history }) => {
  const dispatch = useDispatch();

  if (paymentStatus === "COMPLETED") {
    return (
      <SweetAlert
        success
        title="Congratulations! Your subscription has been successfully completed."
        onConfirm={() => {
          dispatch(resetState());
          history.push("/");
        }}
        show={true}
      ></SweetAlert>
    );
  } else if (
    ["REJECTED", "EXPIRED", "CANCELED"].indexOf(paymentStatus) !== -1
  ) {
    return (
      <SweetAlert
        error
        title={"Error! Your payment transaction is " + paymentStatus + "!"}
        onConfirm={() => {
          dispatch(resetState());
          history.goBack();
        }}
        show={true}
      ></SweetAlert>
    );
  } else if (paymentStatus === "ERROR") {
    return (
      <SweetAlert
        error
        title={"Error! There is an error, please try again later."}
        onConfirm={() => {
          dispatch(resetState());
          history.goBack();
        }}
        show={true}
      ></SweetAlert>
    );
  } else return <></>;
};

PaymentResult.propTypes = {
  paymentStatus: PropTypes.string,
  history: PropTypes.object,
};

export default withRouter(PaymentResult);
