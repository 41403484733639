import { put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import {
  CREATE_SUBSCRIPTION,
  DELETE_SUBSCRIPTION,
  GET_MY_SUBSCRIPTIONS,
  GET_SUBSCRIPTION,
  GET_SUBSCRIPTIONS,
  UPDATE_SUBSCRIPTION,
} from "./actionTypes";
import {
  getSubscriptionSuccess,
  getSubscriptionFail,
  getSubscriptionsSuccess,
  getSubscriptionsFail,
  createSubscriptionSuccess,
  createSubscriptionFail,
  updateSubscriptionSuccess,
  updateSubscriptionFail,
  deleteSubscriptionSuccess,
  deleteSubscriptionFail,
  resetStatus,
  resetState,
  getMySubscriptionsFail,
  getMySubscriptionsSuccess,
} from "./actions";

import { SubscriptionRepositoryImpl } from "core/infrastructure/Subscription/SubscriptionRepositoryImpl";
import { SubscriptionServiceImpl } from "core/usecases/Subscription/SubscriptionService";

function delay(ms) {
  return new Promise(resolve => setTimeout(() => resolve(true), ms));
}

function* fetchSubscription({ payload }) {
  try {
    const SubscriptionRepo = new SubscriptionRepositoryImpl();
    const SubscriptionService = new SubscriptionServiceImpl(SubscriptionRepo);
    const response = yield SubscriptionService.GetSubscription(payload);
    yield put(getSubscriptionSuccess(response));
  } catch (error) {
    yield put(getSubscriptionFail(error));
  }
}

function* fetchSubscriptions({ payload }) {
  const {
    skip,
    first,
    userId,
    packageId,
    paymentStatus,
    price,
    dateGte,
    dateLte,
  } = payload;
  try {
    const SubscriptionRepo = new SubscriptionRepositoryImpl();
    const SubscriptionService = new SubscriptionServiceImpl(SubscriptionRepo);
    const response = yield SubscriptionService.GetSubscriptions(
      skip,
      first,
      userId,
      packageId,
      paymentStatus,
      price,
      dateGte,
      dateLte
    );
    yield put(getSubscriptionsSuccess(response));
  } catch (error) {
    yield put(getSubscriptionsFail(error));
  }
}

function* fetchMySubscriptions({ payload }) {
  const {
    skip,
    first,
    userId,
    packageId,
    paymentStatus,
    price,
    dateGte,
    dateLte,
  } = payload;
  try {
    const SubscriptionRepo = new SubscriptionRepositoryImpl();
    const SubscriptionService = new SubscriptionServiceImpl(SubscriptionRepo);
    const response = yield SubscriptionService.GetSubscriptions(
      skip,
      first,
      userId,
      packageId,
      paymentStatus,
      price,
      dateGte,
      dateLte
    );
    yield put(getMySubscriptionsSuccess(response));
  } catch (error) {
    yield put(getMySubscriptionsFail(error));
  }
}

function* createSubscription({ payload }) {
  try {
    const SubscriptionRepo = new SubscriptionRepositoryImpl();
    const SubscriptionService = new SubscriptionServiceImpl(SubscriptionRepo);
    const response = yield SubscriptionService.CreateSubscription(payload);
    yield put(createSubscriptionSuccess(response));
    yield put(resetState(response));
    yield delay(3000);
    yield put(resetStatus(response));
  } catch (error) {
    yield put(createSubscriptionFail(error));
  }
}

function* updateSubscription({ payload }) {
  try {
    const SubscriptionRepo = new SubscriptionRepositoryImpl();
    const SubscriptionService = new SubscriptionServiceImpl(SubscriptionRepo);
    const response = yield SubscriptionService.UpdateSubscription(payload);
    yield put(updateSubscriptionSuccess(response));
    yield delay(3000);
    yield put(resetStatus(response));
  } catch (error) {
    yield put(updateSubscriptionFail(error));
  }
}

function* deleteSubscription({ payload }) {
  try {
    const SubscriptionRepo = new SubscriptionRepositoryImpl();
    const SubscriptionService = new SubscriptionServiceImpl(SubscriptionRepo);
    const response = yield SubscriptionService.DeleteSubscription(payload);
    yield put(deleteSubscriptionSuccess(response));
    yield delay(3000);
    yield put(resetStatus(response));
  } catch (error) {
    yield put(deleteSubscriptionFail(error));
  }
}

function* SubscriptionSaga() {
  yield takeEvery(GET_SUBSCRIPTION, fetchSubscription);
  yield takeEvery(GET_SUBSCRIPTIONS, fetchSubscriptions);
  yield takeEvery(GET_MY_SUBSCRIPTIONS, fetchMySubscriptions);
  yield takeEvery(CREATE_SUBSCRIPTION, createSubscription);
  yield takeEvery(UPDATE_SUBSCRIPTION, updateSubscription);
  yield takeEvery(DELETE_SUBSCRIPTION, deleteSubscription);
}

export default SubscriptionSaga;
