import React from "react";
import PropTypes from "prop-types";
import { getWallets } from "store/actions";
import { useDataSource } from "hooks/useDataSource";
import { SimpleTable } from "components/SimpleTable";
import { WalletDelete } from "./walletDelete";
import { WalletBalance } from "./walletBalance";
import Loading from "components/Loading/Index";
import { Card, CardBody } from "reactstrap";

const tableColumns = [
  {
    key: "id",
    value: "ID",
  },
  {
    key: "exchange",
    value: "Exchange",
  },
  {
    key: "address",
    value: "Address",
  },
  {
    key: "transferFee",
    value: "Transfer Fee",
  },
  {
    key: "isMain",
    value: "Is Main",
    formatter: (row, value) => (value ? "YES" : "NO"),
  },
  {
    key: "balance",
    value: "Balance",
  },
  {
    key: "id",
    value: "",
    formatter: (row, value) => (
      <>
        <WalletBalance id={row.id} /> <WalletDelete id={row.id} />
      </>
    ),
  },
];

export const WalletList = ({ onRowClick }) => {
  const { wallets, loading } = useDataSource(
    getWallets,
    state => state.WalletState
  );

  return (
    <Card>
      <CardBody>
        <SimpleTable
          columns={tableColumns}
          rows={wallets}
          onRowClick={onRowClick}
        />

        {loading && <Loading />}
      </CardBody>
    </Card>
  );
};

WalletList.propTypes = {
  onRowClick: PropTypes.func,
};
