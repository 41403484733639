import {
  RESET_STATUS,
  CREATE_PAYMENT,
  CREATE_PAYMENT_SUCCESS,
  CREATE_PAYMENT_FAIL,
  RESET_STATE,
  GET_PAYMENT_STATUS,
  CANCEL_PAYMENT,
  CANCEL_PAYMENT_SUCCESS,
  CANCEL_PAYMENT_FAIL,
  GET_PAYMENT_STATUS_SUCCESS,
  GET_PAYMENT_STATUS_FAIL,
} from "./actionTypes";

export const getPaymentStatus = id => ({
  type: GET_PAYMENT_STATUS,
  payload: id,
});

export const getPaymentStatusSuccess = payment => ({
  type: GET_PAYMENT_STATUS_SUCCESS,
  payload: payment,
});

export const getPaymentStatusFail = error => ({
  type: GET_PAYMENT_STATUS_FAIL,
  payload: error,
});

export const createPayment = data => ({
  type: CREATE_PAYMENT,
  payload: data,
});

export const createPaymentSuccess = data => ({
  type: CREATE_PAYMENT_SUCCESS,
  payload: data,
});

export const createPaymentFail = error => ({
  type: CREATE_PAYMENT_FAIL,
  payload: error,
});
export const cancelPayment = data => ({
  type: CANCEL_PAYMENT,
  payload: data,
});

export const cancelPaymentSuccess = data => ({
  type: CANCEL_PAYMENT_SUCCESS,
  payload: data,
});

export const cancelPaymentFail = error => ({
  type: CANCEL_PAYMENT_FAIL,
  payload: error,
});

export const resetState = () => ({
  type: RESET_STATE,
});

export const resetStatus = () => ({
  type: RESET_STATUS,
});
