export class Package {
  id: number;
  name: string;
  description: string;
  duration: number;
  price: string;
  roleId: string;
  roleName: string;
  available: boolean;
  listOrder: number;

  constructor(
    id: number,
    name: string,
    description: string,
    duration: number,
    price: string,
    roleId: string,
    roleName: string,
    available: boolean,
    listOrder: number
  ) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.duration = duration;
    this.price = price;
    this.roleId = roleId;
    this.roleName = roleName;
    this.available = available;
    this.listOrder = listOrder;
  }
}
