export class User {
  userId: string;
  userName: string;
  avatar: string;
  role: string;

  constructor(userId: string, userName: string, avatar: string, role: string) {
    this.userId = userId;
    this.userName = userName;
    this.avatar = avatar;
    this.role = role;
  }
}
