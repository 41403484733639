import { Subscription } from "core/entities/Subscription/Subscription";
import { PaymentStatuses } from "core/entities/Subscription/SubscriptionTypes";
import { updateArrayItem } from "helpers/utils";
import {
  GET_SUBSCRIPTION,
  GET_SUBSCRIPTION_FAIL,
  GET_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_FAIL,
  UPDATE_SUBSCRIPTION_SUCCESS,
  RESET_STATUS,
  GET_SUBSCRIPTIONS,
  GET_SUBSCRIPTIONS_SUCCESS,
  GET_SUBSCRIPTIONS_FAIL,
  CREATE_SUBSCRIPTION,
  CREATE_SUBSCRIPTION_SUCCESS,
  CREATE_SUBSCRIPTION_FAIL,
  DELETE_SUBSCRIPTION,
  DELETE_SUBSCRIPTION_SUCCESS,
  DELETE_SUBSCRIPTION_FAIL,
  RESET_STATE,
  GET_MY_SUBSCRIPTIONS,
  GET_MY_SUBSCRIPTIONS_SUCCESS,
  GET_MY_SUBSCRIPTIONS_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  subscription: {},
  subscriptions: {},
  mySubscriptions: {},
  loading: false,
  saving: false,
  success: false,
  error: {},
};

const SubscriptionState = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTION:
      return {
        ...state,
        loading: true,
        error: {},
      };

    case GET_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscription: action.payload,
        loading: false,
        error: {},
      };

    case GET_SUBSCRIPTION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_SUBSCRIPTIONS:
      return {
        ...state,
        loading: true,
        error: {},
      };

    case GET_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        subscriptions: action.payload,
        loading: false,
        error: {},
      };

    case GET_SUBSCRIPTIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_MY_SUBSCRIPTIONS:
      return {
        ...state,
        loading: true,
        error: {},
      };

    case GET_MY_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        mySubscriptions: action.payload,
        loading: false,
        error: {},
      };

    case GET_MY_SUBSCRIPTIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case CREATE_SUBSCRIPTION:
      return {
        ...state,
        saving: true,
        success: false,
        error: {},
      };

    case CREATE_SUBSCRIPTION_SUCCESS:
      const createSubscriptions = {
        ...state.subscriptions,
        results: [action.payload, ...state.subscriptions.results],
      };
      return {
        ...state,
        subscription: action.payload,
        subscriptions: createSubscriptions,
        saving: false,
        success: true,
        error: {},
      };

    case CREATE_SUBSCRIPTION_FAIL:
      return {
        ...state,
        error: action.payload,
        saving: false,
        success: false,
      };

    case UPDATE_SUBSCRIPTION:
      return {
        ...state,
        saving: true,
        success: false,
        error: {},
      };

    case UPDATE_SUBSCRIPTION_SUCCESS:
      const results = updateArrayItem(
        state.subscriptions.results,
        action.payload
      );
      const updateSubscriptions = {
        ...state.subscriptions,
        results,
      };
      return {
        ...state,
        subscription: action.payload,
        subscriptions: updateSubscriptions,
        saving: false,
        success: true,
        error: {},
      };

    case UPDATE_SUBSCRIPTION_FAIL:
      return {
        ...state,
        error: action.payload,
        saving: false,
        success: false,
      };

    case DELETE_SUBSCRIPTION:
      return {
        ...state,
        saving: true,
        success: false,
        error: {},
      };

    case DELETE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscription: action.payload,
        saving: false,
        success: true,
        error: {},
      };

    case DELETE_SUBSCRIPTION_FAIL:
      return {
        ...state,
        error: action.payload,
        saving: false,
        success: false,
      };

    case RESET_STATE:
      let newSubscription = new Subscription(
        null,
        "",
        "",
        "",
        null,
        0,
        "",
        "",
        PaymentStatuses.PENDING
      );
      delete newSubscription.id;
      return {
        ...state,
        subscription: { ...newSubscription },
      };

    case RESET_STATUS:
      return {
        ...state,
        error: {},
        saving: false,
        success: false,
        loading: false,
      };

    default:
      return state;
  }
};

export default SubscriptionState;
