import { PaymentGatewaySettings } from "../../entities/PaymentGatewaySettings/PaymentGatewaySettings";
import { PaymentGatewaySettingsRepository } from "../../entities/PaymentGatewaySettings/PaymentGatewaySettingsRepository";
import services from "../Services";

class PaymentGatewaySettingsDTO {
  withdrawPeriod: string = "";
  withdrawTriggerQuota: number = 0;
  responseWebhookUrl: string = "";
  isActive: boolean = true;
}

export class PaymentGatewaySettingsRepositoryImpl
  implements PaymentGatewaySettingsRepository
{
  async Get(): Promise<PaymentGatewaySettings> {
    const item: PaymentGatewaySettingsDTO =
      await services.api.getPaymentGatewaySettings();
    return new PaymentGatewaySettings(
      item.withdrawPeriod,
      item.withdrawTriggerQuota,
      item.responseWebhookUrl,
      item.isActive
    );
  }

  async Update(data: PaymentGatewaySettings): Promise<PaymentGatewaySettings> {
    let updateData: any = { ...data };
    delete updateData.isActive;

    const item: PaymentGatewaySettingsDTO =
      await services.api.updatePaymentGatewaySettings(updateData);
    return new PaymentGatewaySettings(
      item.withdrawPeriod,
      item.withdrawTriggerQuota,
      item.responseWebhookUrl,
      item.isActive
    );
  }
}
