import moment from "moment";

export const durationText = duration => {
  let durationTxt = moment.duration(duration, "days").humanize();
  if (durationTxt.indexOf("days") !== -1)
    durationTxt = durationTxt.replace("days", "Gün");
  else if (durationTxt.indexOf("a month") !== -1)
    durationTxt = durationTxt.replace("a month", "Ay");
  else if (durationTxt.indexOf("months") !== -1)
    durationTxt = durationTxt.replace("months", "Ay");
  return durationTxt;
};

export const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);

export const updateArrayItem = (array, item) => {
  let newArray = [...array];
  const index = newArray.findIndex(elem => elem.id === item.id);
  if (index !== -1) {
    const newItem = { ...array[index], ...item };
    newArray.splice(index, 1, newItem);
  }
  return newArray;
};

export const deleteItemFromArrayById = (array, id) => {
  let newArray = [...array];
  const index = newArray.findIndex(elem => elem.id === id);
  if (index !== -1) newArray.splice(index, 1);
  return newArray;
};

export const serializeDTO = (dtoClass, data) => {
  const properties = Object.getOwnPropertyNames(new dtoClass());
  const serializedObject = {};
  properties.forEach(propertyName => {
    serializedObject[propertyName] = data[propertyName];
  });
  return serializedObject;
};

export const startDate = date => {
  if (date) {
    const start = moment(date).format("YYYY-MM-DDT00:00:00.000[Z]");
    return start;
  }
  return date;
};

export const endDate = date => {
  if (date) {
    const end = moment(date).format("YYYY-MM-DDT23:59:00.000[Z]");
    return end;
  }
  return date;
};

export const htmlToText = html => {
  let div = document.createElement("div");
  div.innerHTML = html;
  const text = div.textContent || div.innerText || "";
  return text;
};

export const handleExpireDuration = expire => {
  const end = moment.utc(expire);
  const start = moment();
  const duration = moment.duration(end.diff(start)).asSeconds();
  return duration > 0 ? duration + 60 : 0;
};

export async function copyTextToClipboard(text) {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand("copy", true, text);
  }
}

export const usersToSelectOptions = users => {
  if (typeof users === "object" && users.length > 0) {
    return users.map(user => {
      return {
        value: user.userId,
        label: user.userName,
      };
    });
  }
  return [];
};
