import { Role } from "../../entities/Role/Role";
import { RoleRepository } from "../../entities/Role/RoleRepository";
import services from "../Services";

class RoleDTO {
  id: number = 0;
  name: string = "";
}

export class RoleRepositoryImpl implements RoleRepository {
  async GetAll(): Promise<Role[]> {
    const items = await services.api.getRoles();
    return items.map((item: RoleDTO) => new Role(item.id, item.name));
  }
}
