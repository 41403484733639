import React from "react";
import PropTypes from "prop-types";
import { withDeletableResource } from "hocs/withDeletableResource";
import { Button } from "reactstrap";
import { deletePackage } from "store/actions";

const resourceName = "pack";
const deleteSourceFunc = deletePackage;
const statusSelectorFunc = state => {
  return {
    saving: state.PackageState.saving,
    success: state.PackageState.success,
    error: state.PackageState.error,
  };
};

const PackageDeleteCont = ({ id }) =>
  withDeletableResource(
    ({ pack, status, onDeletePack }) => {
      return (
        <Button
          color="danger-outline"
          size="sm"
          style={{ margin: "-5px 0px" }}
          onClick={onDeletePack}
        >
          <span className="fa fa-times text-danger"></span>
        </Button>
      );
    },
    resourceName,
    id,
    deleteSourceFunc,
    statusSelectorFunc
  );

export const PackageDelete = id => {
  const Component = PackageDeleteCont(id);
  return <Component />;
};

PackageDelete.propTypes = {
  id: PropTypes.number.isRequired,
};
