import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { revokeToken } from "store/actions";
import Loading from "components/Loading/Index";

const Logout = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector(state => state.Auth);

  useEffect(() => {
    dispatch(revokeToken(auth.accessToken));

    return () => {};
  }, []);

  return <>{auth?.accessToken ? <Loading /> : <Redirect to="/" />}</>;
};

export default Logout;
