export class PackageDTO {
  id: number = 0;
  name: string = "";
  description: string = "";
  duration: number = 0;
  price: string = "";
  roleId: string = "";
  roleName: string = "";
  available: boolean = false;
  listOrder: number = 99;
}

export class CreatePackageDTO {
  name: string = "";
  description: string = "";
  duration: number = 0;
  price: string = "";
  roleId: string = "";
  roleName: string = "";
  available: boolean = false;
  listOrder: number = 99;
}

export class UpdatePackageDTO {
  id: number = 0;
  name: string = "";
  description: string = "";
  duration: number = 0;
  price: string = "";
  roleId: string = "";
  roleName: string = "";
  available: boolean = false;
  listOrder: number = 99;
}
