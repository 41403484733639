import { put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import {
  CREATE_PACKAGE,
  DELETE_PACKAGE,
  GET_PACKAGE,
  GET_PACKAGES,
  UPDATE_PACKAGE,
} from "./actionTypes";
import {
  getPackageSuccess,
  getPackageFail,
  getPackagesSuccess,
  getPackagesFail,
  createPackageSuccess,
  createPackageFail,
  updatePackageSuccess,
  updatePackageFail,
  deletePackageSuccess,
  deletePackageFail,
  resetStatus,
  resetState,
} from "./actions";

import { PackageRepositoryImpl } from "core/infrastructure/Package/PackageRepositoryImpl";
import { PackageServiceImpl } from "core/usecases/Package/PackageService";

function delay(ms) {
  return new Promise(resolve => setTimeout(() => resolve(true), ms));
}

function* fetchPackage({ payload }) {
  try {
    const PackageRepo = new PackageRepositoryImpl();
    const PackageService = new PackageServiceImpl(PackageRepo);
    const response = yield PackageService.GetPackage(payload);
    yield put(getPackageSuccess(response));
  } catch (error) {
    yield put(getPackageFail(error));
  }
}

function* fetchPackages() {
  try {
    const PackageRepo = new PackageRepositoryImpl();
    const PackageService = new PackageServiceImpl(PackageRepo);
    const response = yield PackageService.GetPackages();
    yield put(getPackagesSuccess(response));
  } catch (error) {
    yield put(getPackagesFail(error));
  }
}

function* createPackage({ payload }) {
  try {
    const PackageRepo = new PackageRepositoryImpl();
    const PackageService = new PackageServiceImpl(PackageRepo);
    const response = yield PackageService.CreatePackage(payload);
    yield put(createPackageSuccess(response));
    yield put(resetState(response));
    yield delay(3000);
    yield put(resetStatus(response));
  } catch (error) {
    yield put(createPackageFail(error));
  }
}

function* updatePackage({ payload }) {
  try {
    const PackageRepo = new PackageRepositoryImpl();
    const PackageService = new PackageServiceImpl(PackageRepo);
    const response = yield PackageService.UpdatePackage(payload);
    yield put(updatePackageSuccess(response));
    yield delay(3000);
    yield put(resetStatus(response));
  } catch (error) {
    yield put(updatePackageFail(error));
  }
}

function* deletePackage({ payload }) {
  try {
    const PackageRepo = new PackageRepositoryImpl();
    const PackageService = new PackageServiceImpl(PackageRepo);
    const response = yield PackageService.DeletePackage(payload);
    yield put(deletePackageSuccess(response));
    yield delay(3000);
    yield put(resetStatus());
  } catch (error) {
    yield put(deletePackageFail(error));
  }
}

function* PackageSaga() {
  yield takeEvery(GET_PACKAGE, fetchPackage);
  yield takeEvery(GET_PACKAGES, fetchPackages);
  yield takeEvery(CREATE_PACKAGE, createPackage);
  yield takeEvery(UPDATE_PACKAGE, updatePackage);
  yield takeEvery(DELETE_PACKAGE, deletePackage);
}

export default PackageSaga;
