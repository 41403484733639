import { ApolloClient, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "@apollo/client/link/context";
import store from "../../../store/index";

//const BASE_URL: string = "http://localhost:8000/graphql/";
const BASE_URL: string = "http://api.sarperonder.com/graphql/";

const uploadLink = createUploadLink({
  uri: BASE_URL,
});

const authLink = setContext((_, { headers }) => {
  const { auth } = store.store.getState().Auth;

  return {
    headers: {
      ...headers,
      Authorization: auth?.accessToken ? `Bearer ${auth.accessToken}` : "",
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(uploadLink),
  cache: new InMemoryCache(),
});
