import { client } from "../../HttpClient";
import { GET_CRON_LOGS } from "./graphql";

export const getCronLogs = async (
  skip: number,
  first: number,
  dateGte: string,
  dateLte: string
): Promise<any> => {
  const response = await client.query({
    query: GET_CRON_LOGS,
    variables: {
      skip,
      first,
      dateGte,
      dateLte,
    },
    fetchPolicy: "network-only",
  });
  return response.data.cronLogs;
};
