import React from "react";
import PropTypes from "prop-types";
import { SimpleTable } from "components/SimpleTable";
import { htmlToText } from "helpers/utils";
import { useDataSource } from "hooks/useDataSource";
import { getPackages } from "store/actions";
import { PackageDelete } from "./packageDelete";
import Loading from "components/Loading/Index";
import { Card, CardBody } from "reactstrap";

const tableColumns = [
  {
    key: "id",
    value: "ID",
  },
  {
    key: "name",
    value: "Name",
  },
  {
    key: "description",
    value: "Description",
    formatter: (row, value) => (
      <div className="shorten">{htmlToText(value)}</div>
    ),
  },
  {
    key: "roleName",
    value: "Role",
  },
  {
    key: "price",
    value: "Price",
  },
  {
    key: "duration",
    value: "Duration",
  },
  {
    key: "available",
    value: "Is Available",
    formatter: (row, value) => (value ? "YES" : "NO"),
  },
  {
    key: "id",
    value: "",
    formatter: (row, value) => <PackageDelete id={row.id} />,
  },
];

export const PackageList = ({ onRowClick }) => {
  const { packages, loading } = useDataSource(
    getPackages,
    state => state.PackageState
  );

  return (
    <Card>
      <CardBody>
        <SimpleTable
          columns={tableColumns}
          rows={packages}
          onRowClick={onRowClick}
        />
        {loading && <Loading />}
      </CardBody>
    </Card>
  );
};

PackageList.propTypes = {
  onRowClick: PropTypes.func,
};
