export class Payment {
  id: number;
  amount: number;
  walletId: number;
  walletAddress: string;
  paymentExpire: string;
  customerId: string;
  orderId: string;

  constructor(
    id: number,
    amount: number,
    walletId: number,
    walletAddress: string,
    paymentExpire: string,
    customerId: string,
    orderId: string
  ) {
    this.id = id;
    this.amount = amount;
    this.walletId = walletId;
    this.walletAddress = walletAddress;
    this.paymentExpire = paymentExpire;
    this.customerId = customerId;
    this.orderId = orderId;
  }
}
