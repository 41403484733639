import { put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_ROLES } from "./actionTypes";
import { getRolesSuccess, getRolesFail } from "./actions";

import { RoleRepositoryImpl } from "core/infrastructure/Role/RoleRepositoryImpl";
import { RoleServiceImpl } from "core/usecases/Role/RoleService";

function* fetchRoles() {
  try {
    const RoleRepo = new RoleRepositoryImpl();
    const RoleService = new RoleServiceImpl(RoleRepo);
    const response = yield RoleService.GetRoles();
    yield put(getRolesSuccess(response));
  } catch (error) {
    yield put(getRolesFail(error));
  }
}

function* RoleSaga() {
  yield takeEvery(GET_ROLES, fetchRoles);
}

export default RoleSaga;
