import { gql } from "@apollo/client";

export const EXCHANGE_CODE = gql`
  mutation exchangeCode($code: String!) {
    exchangeCode(code: $code) {
      response {
        accessToken
        tokenType
        expiresIn
        refreshToken
        scope
        user {
          userId
          userName
          avatar
          role
        }
      }
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation refreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      response {
        accessToken
        tokenType
        expiresIn
        refreshToken
        scope
        user {
          userId
          userName
          avatar
          role
        }
      }
    }
  }
`;

export const REVOKE_TOKEN = gql`
  mutation revokeToken($token: String!) {
    revokeToken(token: $token) {
      response {
        accessToken
        tokenType
        expiresIn
        refreshToken
        scope
        user {
          userId
          userName
          avatar
          role
        }
      }
    }
  }
`;

export const GET_PACKAGES = gql`
  query packages($available: Boolean) {
    packages(available: $available) {
      id
      name
      description
      duration
      price
      roleId
      roleName
      available
      listOrder
    }
  }
`;

export const GET_PACKAGE = gql`
  query package($id: Int!) {
    package(id: $id) {
      id
      name
      description
      duration
      price
      roleId
      roleName
      available
      listOrder
    }
  }
`;

export const CREATE_PACKAGE = gql`
  mutation createPackage($data: CreatePackageInput!) {
    createPackage(data: $data) {
      package {
        id
        name
        description
        duration
        price
        roleId
        roleName
        available
        listOrder
      }
    }
  }
`;

export const UPDATE_PACKAGE = gql`
  mutation updatePackage($data: UpdatePackageInput!) {
    updatePackage(data: $data) {
      package {
        id
        name
        description
        duration
        price
        roleId
        roleName
        available
        listOrder
      }
    }
  }
`;

export const DELETE_PACKAGE = gql`
  mutation deletePackage($id: Int!) {
    deletePackage(id: $id) {
      package {
        id
        name
        description
        duration
        price
        roleId
        roleName
        available
        listOrder
      }
    }
  }
`;

export const GET_PAYMENT_GATEWAY_SETTINGS = gql`
  query settings {
    settings {
      withdrawPeriod
      withdrawTriggerQuota
      responseWebhookUrl
      isActive
    }
  }
`;

export const UPDATE_PAYMENT_GATEWAY_SETTINGS = gql`
  mutation updatePaymentSettings($data: UpdatePaymentSettingsInput!) {
    updatePaymentSettings(data: $data) {
      settings {
        withdrawPeriod
        withdrawTriggerQuota
        responseWebhookUrl
        isActive
      }
    }
  }
`;

export const GET_WALLET = gql`
  query wallet($id: Int!) {
    wallet(id: $id) {
      id
      exchange
      subAccount
      address
      apiKey
      apiSecret
      isMain
      transferFee
    }
  }
`;

export const GET_WALLETS = gql`
  query wallets {
    wallets {
      id
      exchange
      subAccount
      address
      apiKey
      apiSecret
      isMain
      transferFee
    }
  }
`;

export const GET_WALLET_BALANCE = gql`
  query walletBalance($id: Int!) {
    walletBalance(id: $id) {
      id
      balance
    }
  }
`;

export const GET_WALLET_TRANSACTIONS = gql`
  query walletTransactions($id: Int!, $skip: Int, $limit: Int) {
    walletTransactions(id: $id, skip: $skip, limit: $limit) {
      id
      date
      amount
      txId
      transferFee
      transferredAmount
      customerId
      orderId
      status
      rejectReason
    }
  }
`;
export const GET_WALLET_DEPOSITS = gql`
  query walletDeposits($id: Int!, $since: DateTime, $limit: Int) {
    walletDeposits(id: $id, since: $since, limit: $limit) {
      id
      datetime
      txid
      addresTo
      amount
      status
    }
  }
`;

export const CREATE_WALLET = gql`
  mutation createWallet($data: CreateWalletInput!) {
    createWallet(data: $data) {
      wallet {
        id
        exchange
        subAccount
        address
        apiKey
        apiSecret
        isMain
        transferFee
      }
    }
  }
`;

export const UPDATE_WALLET = gql`
  mutation updateWallet($data: UpdateWalletInput!) {
    updateWallet(data: $data) {
      wallet {
        id
        exchange
        subAccount
        address
        apiKey
        apiSecret
        isMain
        transferFee
      }
    }
  }
`;

export const DELETE_WALLET = gql`
  mutation deleteWallet($id: Int!) {
    deleteWallet(id: $id) {
      wallet {
        id
      }
    }
  }
`;

export const CREATE_PAYMENT = gql`
  mutation createPayment($data: CreatePaymentInput!) {
    createPayment(data: $data) {
      payment {
        amount
        walletId
        walletAddress
        paymentExpire
        customerId
        orderId
      }
    }
  }
`;

export const GET_PAYMENT_STATUS = gql`
  query paymentStatus($id: Int!) {
    subscription(id: $id) {
      id
      paymentStatus
    }
  }
`;

export const CANCEL_PAYMENT = gql`
  mutation cancelPayment($data: CancelPaymentInput!) {
    cancelPayment(data: $data) {
      payment {
        id
      }
    }
  }
`;

export const GET_ROLES = gql`
  query roles {
    roles {
      id
      name
    }
  }
`;

export const GET_SUBSCRIPTION = gql`
  query subscription($id: Int!) {
    subscription(id: $id) {
      id
      date
      userId
      userName
      package {
        id
        name
      }
      duration
      price
      expireDate
      paymentStatus
    }
  }
`;
export const GET_SUBSCRIPTIONS = gql`
  query subscriptions(
    $skip: Int
    $first: Int
    $userId: String
    $packageId: Int
    $paymentStatus: PaymentStatuses
    $price: String
    $dateGte: String
    $dateLte: String
  ) {
    subscriptions(
      skip: $skip
      first: $first
      userId: $userId
      packageId: $packageId
      paymentStatus: $paymentStatus
      price: $price
      dateGte: $dateGte
      dateLte: $dateLte
    ) {
      totalCount
      results {
        id
        date
        userId
        userName
        package {
          id
          name
        }
        duration
        price
        expireDate
        paymentStatus
      }
    }
  }
`;
export const CREATE_SUBSCRIPTION = gql`
  mutation createSubscription($data: CreateSubscriptionInput!) {
    createSubscription(data: $data) {
      subscription {
        id
        date
        userId
        userName
        package {
          id
          name
        }
        duration
        price
        expireDate
        paymentStatus
      }
    }
  }
`;

export const UPDATE_SUBSCRIPTION = gql`
  mutation updateSubscription($data: UpdateSubscriptionInput!) {
    updateSubscription(data: $data) {
      subscription {
        id
        date
        userId
        userName
        package {
          id
          name
        }
        duration
        price
        expireDate
        paymentStatus
      }
    }
  }
`;

export const DELETE_SUBSCRIPTION = gql`
  mutation deleteSubscription($id: Int!) {
    deleteSubscription(id: $id) {
      subscription {
        id
        date
        userId
        userName
        package {
          id
          name
        }
        duration
        price
        expireDate
        paymentStatus
      }
    }
  }
`;

export const GET_USERS = gql`
  query users($limit: Int, $after: String) {
    users(limit: $limit, after: $after) {
      userId
      userName
    }
  }
`;

export const GET_USER = gql`
  query user($id: String!) {
    user(id: $id) {
      userId
      userName
    }
  }
`;

export const GET_CRON_LOGS = gql`
  query cronLogs($skip: Int, $first: Int, $dateGte: String, $dateLte: String) {
    cronLogs(skip: $skip, first: $first, dateGte: $dateGte, dateLte: $dateLte) {
      totalCount
      results {
        id
        date
        details
      }
    }
  }
`;
