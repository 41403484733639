export const GET_WALLET = "GET_WALLET";
export const GET_WALLET_SUCCESS = "GET_WALLET_SUCCESS";
export const GET_WALLET_FAIL = "GET_WALLET_FAIL";

export const GET_WALLET_BALANCE = "GET_WALLET_BALANCE";
export const GET_WALLET_BALANCE_SUCCESS = "GET_WALLET_BALANCE_SUCCESS";
export const GET_WALLET_BALANCE_FAIL = "GET_WALLET_BALANCE_FAIL";

export const GET_WALLETS = "GET_WALLETS";
export const GET_WALLETS_SUCCESS = "GET_WALLETS_SUCCESS";
export const GET_WALLETS_FAIL = "GET_WALLETS_FAIL";

export const CREATE_WALLET = "CREATE_WALLET";
export const CREATE_WALLET_SUCCESS = "CREATE_WALLET_SUCCESS";
export const CREATE_WALLET_FAIL = "CREATE_WALLET_FAIL";

export const UPDATE_WALLET = "UPDATE_WALLET";
export const UPDATE_WALLET_SUCCESS = "UPDATE_WALLET_SUCCESS";
export const UPDATE_WALLET_FAIL = "UPDATE_WALLET_FAIL";

export const DELETE_WALLET = "DELETE_WALLET";
export const DELETE_WALLET_SUCCESS = "DELETE_WALLET_SUCCESS";
export const DELETE_WALLET_FAIL = "DELETE_WALLET_FAIL";

export const RESET_STATE = "RESET_STATE";

export const RESET_STATUS = "RESET_STATUS";
