import {
  EXCHANGE_CODE,
  EXCHANGE_CODE_FAIL,
  EXCHANGE_CODE_SUCCESS,
  LOGOUT,
  REFRESH_TOKEN,
  REFRESH_TOKEN_FAIL,
  REFRESH_TOKEN_SUCCESS,
  REVOKE_TOKEN,
  REVOKE_TOKEN_FAIL,
  REVOKE_TOKEN_SUCCESS,
} from "./actionTypes";

export const exchangeCode = code => ({
  type: EXCHANGE_CODE,
  payload: { code },
});

export const exchangeCodeSuccess = payload => ({
  type: EXCHANGE_CODE_SUCCESS,
  payload,
});

export const exchangeCodeFail = error => ({
  type: EXCHANGE_CODE_FAIL,
  payload: error,
});

export const refreshToken = refreshToken => ({
  type: REFRESH_TOKEN,
  payload: { refreshToken },
});

export const refreshTokenSuccess = payload => ({
  type: REFRESH_TOKEN_SUCCESS,
  payload,
});

export const refreshTokenFail = error => ({
  type: REFRESH_TOKEN_FAIL,
  payload: error,
});

export const revokeToken = token => ({
  type: REVOKE_TOKEN,
  payload: { token },
});

export const revokeTokenSuccess = payload => ({
  type: REVOKE_TOKEN_SUCCESS,
  payload,
});

export const revokeTokenFail = error => ({
  type: REVOKE_TOKEN_FAIL,
  payload: error,
});

export const logOut = () => ({
  type: LOGOUT,
});
