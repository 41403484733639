import { User } from "../../entities/User/User";
import { UserRepository } from "../../entities/User/UserRepository";
import services from "../Services";

class UserDTO {
  userId: string = "";
  userName: string = "";
  avatar: string = "";
  role: string = "";
}

export class UserRepositoryImpl implements UserRepository {
  async GetById(userId: number): Promise<User> {
    const item: UserDTO = await services.api.getUser(userId);
    return new User(item.userId, item.userName, item.avatar, item.role);
  }
  async GetAll(limit: number, after: string): Promise<User[]> {
    const items = await services.api.getUsers(limit, after);
    return items.map(
      (item: UserDTO) =>
        new User(item.userId, item.userName, item.avatar, item.role)
    );
  }
}
