import React from "react";
import MetaTags from "react-meta-tags";
import { RowList } from "components/RowList";
import { useDataSource } from "hooks/useDataSource";
import { Container } from "reactstrap";
import { getPackages } from "store/actions";
import { SubscriptionPlan } from "./SubscriptionPlan";

const SubscriptionPlans = () => {
  const packages = useDataSource(
    getPackages,
    state => state.PackageState.packages
  ).filter(pack => pack.available);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Üyelik Planları</title>
        </MetaTags>
        <Container fluid>
          <h4 className="mb-3">Üyelik Planları</h4>

          <RowList
            items={packages}
            resourceName="plan"
            itemComponent={SubscriptionPlan}
            colProps={{
              sm: 3,
            }}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SubscriptionPlans;
