import React from "react";
import MetaTags from "react-meta-tags";
import { Container } from "reactstrap";
import { MySubscriptionList } from "./mySubscriptionList";

const MySubscriptions = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Üyeliğim</title>
        </MetaTags>
        <Container fluid>
          <h4 className="mb-3">Üyeliğim</h4>
        </Container>

        <MySubscriptionList />
      </div>
    </React.Fragment>
  );
};

export default MySubscriptions;
