import { Role } from "../../entities/Role/Role";
import { RoleRepository } from "../../entities/Role/RoleRepository";

export interface RoleService {
  GetRoles(): Promise<Role[]>;
}

export class RoleServiceImpl implements RoleService {
  packageRepo: RoleRepository;

  constructor(ar: RoleRepository) {
    this.packageRepo = ar;
  }

  async GetRoles(): Promise<Role[]> {
    return this.packageRepo.GetAll();
  }
}
