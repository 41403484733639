import { client } from "../../HttpClient";
import {
  GET_PAYMENT_GATEWAY_SETTINGS,
  UPDATE_PAYMENT_GATEWAY_SETTINGS,
} from "./graphql";

export const getPaymentGatewaySettings = async (): Promise<any> => {
  const response = await client.query({
    query: GET_PAYMENT_GATEWAY_SETTINGS,
  });
  return response.data.settings;
};

export const updatePaymentGatewaySettings = async (
  data: object
): Promise<any> => {
  const response = await client.mutate({
    mutation: UPDATE_PAYMENT_GATEWAY_SETTINGS,
    variables: {
      data: data,
    },
  });
  return response.data.updatePaymentSettings.settings;
};
