import {
  CancelPaymentDTO,
  CreatePaymentDTO,
  PaymentDTO,
  PaymentStatusDTO,
} from "core/entities/Payment/PaymentTypes";
import { serializeDTO } from "helpers/utils";
import { Payment } from "../../entities/Payment/Payment";
import { PaymentRepository } from "../../entities/Payment/PaymentRepository";
import services from "../Services";

export class PaymentRepositoryImpl implements PaymentRepository {
  async GetStatusById(id: number): Promise<PaymentStatusDTO> {
    const item: PaymentStatusDTO = await services.api.getPaymentStatusById(id);
    return item;
  }

  async Create(data: CreatePaymentDTO): Promise<Payment> {
    const serializedData = serializeDTO(CreatePaymentDTO, data);

    const item: PaymentDTO = await services.api.createPayment(serializedData);
    return new Payment(
      item.id,
      item.amount,
      item.walletId,
      item.walletAddress,
      item.paymentExpire,
      item.customerId,
      item.orderId
    );
  }

  async Cancel(data: CancelPaymentDTO): Promise<Payment> {
    const serializedData = serializeDTO(CancelPaymentDTO, data);
    const item: PaymentDTO = await services.api.cancelPayment(serializedData);
    return item;
  }
}
