import React, { forwardRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { PaymentStatuses } from "core/entities/Subscription/SubscriptionTypes";
import DatePicker from "react-datepicker";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { useDataSource } from "hooks/useDataSource";
import { getUsers } from "store/actions";
import { usersToSelectOptions } from "helpers/utils";

const DateInput = forwardRef(({ value, onClick }, ref) => (
  <Input
    ref={ref}
    type="text"
    value={value}
    onClick={onClick}
    onChange={() => {}}
  />
));

DateInput.propTypes = {
  value: PropTypes.any,
  onClick: PropTypes.func,
};

DateInput.displayName = "DateInput";

export const SubscriptionFilters = ({ filters, packages, onChange }) => {
  const users = useDataSource(
    () => getUsers(1000),
    state => state.UserState.users
  );
  const [newFilters, setNewFilters] = useState({ ...filters });
  const { userId, packageId, price, paymentStatus, dateGte, dateLte } =
    newFilters;

  const handleOnChange = newFilter => {
    setNewFilters({
      ...newFilters,
      ...newFilter,
    });
  };

  const applyFilters = () => {
    onChange(newFilters);
  };

  return (
    <Card>
      <CardBody>
        <Row>
          <Col>
            <FormGroup className="mb-2 me-sm-2 mb-sm-0">
              <Label className="me-sm-2" for="userId">
                User
              </Label>
              <Select
                options={usersToSelectOptions(users)}
                onChange={option =>
                  handleOnChange({
                    userId: option.value,
                  })
                }
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup className="mb-2 me-sm-2 mb-sm-0">
              <Label className="me-sm-2" for="packageId">
                Package
              </Label>
              <Input
                id="packageId"
                name="packageId"
                type="select"
                value={packageId == null ? 0 : packageId}
                onChange={e =>
                  handleOnChange({
                    packageId: Number(e.target.value),
                  })
                }
              >
                <option value={""}>Select Package</option>
                {packages &&
                  packages
                    .filter(pack => pack.available)
                    .map((pack, i) => (
                      <option value={pack.id} key={i}>
                        {pack.name}
                      </option>
                    ))}
              </Input>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup className="mb-2 me-sm-2 mb-sm-0">
              <Label className="me-sm-2" for="price">
                Price
              </Label>
              <Input
                id="price"
                name="price"
                placeholder="Enter price..."
                type="text"
                value={price == null ? "" : price}
                onChange={e => handleOnChange({ price: e.target.value })}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup className="mb-2 me-sm-2 mb-sm-0">
              <Label className="me-sm-2" for="paymentStatus">
                Payment Status
              </Label>
              <Input
                id="paymentStatus"
                name="paymentStatus"
                type="select"
                value={paymentStatus == null ? 0 : paymentStatus}
                onChange={e =>
                  handleOnChange({
                    paymentStatus: e.target.value,
                  })
                }
              >
                <option value={""}>Select Status</option>
                {Object.values(PaymentStatuses)
                  .filter(value => typeof value === "number")
                  .map((key, i) => (
                    <option key={i}>{PaymentStatuses[key]}</option>
                  ))}
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col sm={3}>
            <FormGroup className="mb-2 me-sm-2 mb-sm-0">
              <Label className="me-sm-2" for="date">
                Date
              </Label>
              <Row>
                <Col sm={6}>
                  <DatePicker
                    selected={dateGte}
                    maxDate={dateLte}
                    dateFormat="dd/MM/yyyy"
                    isClearable
                    onChange={date => {
                      handleOnChange({
                        dateGte: date,
                      });
                    }}
                    customInput={<DateInput />}
                  />
                </Col>
                <Col sm={6}>
                  <DatePicker
                    selected={dateLte}
                    minDate={dateGte}
                    dateFormat="dd/MM/yyyy"
                    isClearable
                    onChange={date =>
                      handleOnChange({
                        dateLte: date,
                      })
                    }
                    customInput={<DateInput />}
                  />
                </Col>
              </Row>
            </FormGroup>
          </Col>
          <Col sm={3}>
            <Button
              color="primary"
              className="w-md"
              onClick={applyFilters}
              style={{ marginTop: 28 }}
            >
              Filter
            </Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

SubscriptionFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  packages: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};
