import { WalletBalanceDTO } from "core/entities/Wallet/WalletTypes";
import { Wallet } from "../../entities/Wallet/Wallet";
import { WalletRepository } from "../../entities/Wallet/WalletRepository";

export interface WalletService {
  GetWallet(id: number): Promise<Wallet>;
  GetWalletBalance(id: number): Promise<WalletBalanceDTO>;
  GetWallets(): Promise<Wallet[]>;
  CreateWallet(data: Wallet): Promise<Wallet>;
  UpdateWallet(data: Wallet): Promise<Wallet>;
  DeleteWallet(id: number): Promise<number>;
}

export class WalletServiceImpl implements WalletService {
  walletRepo: WalletRepository;

  constructor(ar: WalletRepository) {
    this.walletRepo = ar;
  }

  async GetWallet(id: number): Promise<Wallet> {
    return this.walletRepo.GetById(id);
  }

  async GetWalletBalance(id: number): Promise<WalletBalanceDTO> {
    return this.walletRepo.GetBalanceById(id);
  }

  async GetWallets(): Promise<Wallet[]> {
    return this.walletRepo.GetAll();
  }

  async CreateWallet(data: Wallet): Promise<Wallet> {
    return this.walletRepo.Create(data);
  }

  async UpdateWallet(data: Wallet): Promise<Wallet> {
    return this.walletRepo.Update(data);
  }

  async DeleteWallet(id: number): Promise<number> {
    return this.walletRepo.Delete(id);
  }
}
