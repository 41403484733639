import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import { getWalletBalance } from "store/actions";
import { useDispatch } from "react-redux";

export const WalletBalance = ({ id }) => {
  const dispatch = useDispatch();

  const onClick = e => {
    e.stopPropagation();
    dispatch(getWalletBalance(id));
  };

  return (
    <Button
      color="success-outline"
      size="sm"
      style={{ margin: "-5px 0px" }}
      onClick={onClick}
    >
      <span className="fa fa-sync text-success"></span>
    </Button>
  );
};

WalletBalance.propTypes = {
  id: PropTypes.number.isRequired,
};
