import { PaymentStatuses } from "../Subscription/SubscriptionTypes";

export class PaymentDTO {
  id: number = 0;
  amount: number = 0;
  walletId: number = 0;
  walletAddress: string = "";
  paymentExpire: string = "";
  customerId: string = "";
  orderId: string = "";
}

export class CreatePaymentDTO {
  userId: string = "";
  userName: string = "";
  packageId: number = 0;
  extendSubscriptionId: number = 0;
}

export class CancelPaymentDTO {
  walletId: number = 0;
  orderId: number = 0;
}

export class PaymentStatusDTO {
  id: number = 0;
  paymentStatus: PaymentStatuses = PaymentStatuses.PENDING;
}
