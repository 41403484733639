import { Package } from "../Package/Package";
import { Subscription } from "./Subscription";

export enum PaymentStatuses {
  PENDING,
  COMPLETED,
  REJECTED,
  EXPIRED,
  CANCELED,
  ERROR,
  EXTENDED,
}

export class SubscriptionDTO {
  id: number = 0;
  date: string = "";
  userId: string = "";
  userName: string = "";
  package: Package = new Package(0, "", "", 0, "", "", "", false, 99);
  duration: number = 0;
  price: string = "";
  expireDate: string = "";
  paymentStatus: PaymentStatuses = PaymentStatuses.PENDING;
}

export interface SubscriptionListType {
  totalCount: number;
  results: Subscription[];
}

export class CreateSubscriptionDTO {
  userId: string = "";
  userName: string = "";
  packageId: number = 0;
  duration: number = 0;
  price: string = "";
  paymentStatus: PaymentStatuses = PaymentStatuses.PENDING;
}

export class UpdateSubscriptionDTO {
  id: number = 0;
  userId: string = "";
  userName: string = "";
  packageId: number = 0;
  duration: number = 0;
  price: string = "";
  paymentStatus: PaymentStatuses = PaymentStatuses.PENDING;
}
