import { Package } from "../Package/Package";
import { PaymentStatuses } from "./SubscriptionTypes";

export class Subscription {
  id: number;
  date: string;
  userId: string;
  userName: string;
  package: Package;
  duration: number;
  price: string;
  expireDate: string;
  paymentStatus: PaymentStatuses;

  constructor(
    id: number,
    date: string,
    userId: string,
    userName: string,
    pack: Package,
    duration: number,
    price: string,
    expireDate: string,
    paymentStatus: PaymentStatuses
  ) {
    this.id = id;
    this.date = date;
    this.userId = userId;
    this.userName = userName;
    this.package = pack;
    this.duration = duration;
    this.price = price;
    this.expireDate = expireDate;
    this.paymentStatus = paymentStatus;
  }
}
