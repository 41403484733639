import { User } from "../../entities/User/User";
import { UserRepository } from "../../entities/User/UserRepository";

export interface UserService {
  GetUser(id: number): Promise<User>;
  GetUsers(limit: number, after: string): Promise<User[]>;
}

export class UserServiceImpl implements UserService {
  userRepo: UserRepository;

  constructor(ar: UserRepository) {
    this.userRepo = ar;
  }

  async GetUser(id: number): Promise<User> {
    return this.userRepo.GetById(id);
  }

  async GetUsers(limit: number, after: string): Promise<User[]> {
    return this.userRepo.GetAll(limit, after);
  }
}
