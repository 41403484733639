import { Wallet } from "core/entities/Wallet/Wallet";
import { deleteItemFromArrayById, updateArrayItem } from "helpers/utils";
import {
  GET_WALLET,
  GET_WALLET_FAIL,
  GET_WALLET_SUCCESS,
  UPDATE_WALLET,
  UPDATE_WALLET_FAIL,
  UPDATE_WALLET_SUCCESS,
  RESET_STATUS,
  GET_WALLETS,
  GET_WALLETS_SUCCESS,
  GET_WALLETS_FAIL,
  CREATE_WALLET,
  CREATE_WALLET_SUCCESS,
  CREATE_WALLET_FAIL,
  DELETE_WALLET,
  DELETE_WALLET_SUCCESS,
  DELETE_WALLET_FAIL,
  RESET_STATE,
  GET_WALLET_BALANCE,
  GET_WALLET_BALANCE_SUCCESS,
  GET_WALLET_BALANCE_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  wallet: {},
  wallets: [],
  loading: false,
  saving: false,
  success: false,
  error: {},
};

const PaymentGatewayState = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_WALLET:
      return {
        ...state,
        loading: true,
        error: {},
      };

    case GET_WALLET_SUCCESS:
      return {
        ...state,
        wallet: action.payload,
        loading: false,
        error: {},
      };

    case GET_WALLET_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_WALLET_BALANCE:
      return {
        ...state,
        loading: true,
        error: {},
      };

    case GET_WALLET_BALANCE_SUCCESS:
      const walletsBalance = updateArrayItem(state.wallets, action.payload);
      return {
        ...state,
        wallets: walletsBalance,
        loading: false,
        error: {},
      };

    case GET_WALLET_BALANCE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_WALLETS:
      return {
        ...state,
        loading: true,
        error: {},
      };

    case GET_WALLETS_SUCCESS:
      return {
        ...state,
        wallets: action.payload,
        loading: false,
        error: {},
      };

    case GET_WALLETS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case CREATE_WALLET:
      return {
        ...state,
        saving: true,
        success: false,
        error: {},
      };

    case CREATE_WALLET_SUCCESS:
      const createWallets = [...state.wallets, action.payload];
      return {
        ...state,
        wallet: action.payload,
        wallets: createWallets,
        saving: false,
        success: true,
        error: {},
      };

    case CREATE_WALLET_FAIL:
      return {
        ...state,
        error: action.payload,
        saving: false,
        success: false,
      };

    case UPDATE_WALLET:
      return {
        ...state,
        saving: true,
        success: false,
        error: {},
      };

    case UPDATE_WALLET_SUCCESS:
      const updateWallets = updateArrayItem(state.wallets, action.payload);
      return {
        ...state,
        wallet: action.payload,
        wallets: updateWallets,
        saving: false,
        success: true,
        error: {},
      };

    case UPDATE_WALLET_FAIL:
      return {
        ...state,
        error: action.payload,
        saving: false,
        success: false,
      };

    case DELETE_WALLET:
      return {
        ...state,
        saving: true,
        success: false,
        error: {},
      };

    case DELETE_WALLET_SUCCESS:
      const deleteWallets = deleteItemFromArrayById(
        state.wallets,
        action.payload
      );
      return {
        ...state,
        wallet: action.payload,
        wallets: deleteWallets,
        saving: false,
        success: true,
        error: {},
      };

    case DELETE_WALLET_FAIL:
      return {
        ...state,
        error: action.payload,
        saving: false,
        success: false,
      };

    case RESET_STATE:
      let newWallet = new Wallet(null, "", "", "", "", "", false, 0);
      delete newWallet.id;
      return {
        ...state,
        wallet: { ...newWallet },
      };

    case RESET_STATUS:
      return {
        ...state,
        error: {},
        saving: false,
        success: false,
        loading: false,
      };

    default:
      return state;
  }
};

export default PaymentGatewayState;
