import { GET_ROLES, GET_ROLES_SUCCESS, GET_ROLES_FAIL } from "./actionTypes";

const INIT_STATE = {
  roles: [],
  loading: false,
  error: {},
};

const RoleState = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ROLES:
      return {
        ...state,
        loading: true,
        error: {},
      };

    case GET_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.payload,
        loading: false,
        error: {},
      };

    case GET_ROLES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default RoleState;
