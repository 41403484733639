import { CronLogListType } from "core/entities/CronLog/CronLogTypes";
import { CronLogRepository } from "../../entities/CronLog/CronLogRepository";
import services from "../Services";

export class CronLogRepositoryImpl implements CronLogRepository {
  async GetAll(
    skip: number,
    first: number,
    dateGte: string,
    dateLte: string
  ): Promise<CronLogListType> {
    const response: CronLogListType = await services.api.getCronLogs(
      skip,
      first,
      dateGte,
      dateLte
    );
    return response;
  }
}
