import React from "react";
import { Redirect } from "react-router-dom";

import SubscriptionPlans from "../pages/SubscriptionPlans/index";
import Payment from "../pages/Payment/index";
import PaymentGatewaySettings from "../pages/PaymentGatewaySettings/index";
import Wallets from "../pages/Wallets/index";
import Packages from "../pages/Packages/index";

import ExchangeCode from "../pages/Authentication/ExchangeCode";
import Logout from "../pages/Authentication/Logout";
import Subscriptions from "../pages/Subscriptions";
import MySubscriptions from "../pages/MySubscriptions";
import CronLogs from "../pages/CronLogs";

const authProtectedRoutes = [
  { path: "/subscription-plans", component: SubscriptionPlans },
  { path: "/payment/:id", component: Payment },
  { path: "/payment/:id/:extendSubscriptionId", component: Payment },
  { path: "/my-subscriptions", component: MySubscriptions },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/subscription-plans" />,
  },
];

const adminProtectedRoutes = [
  { path: "/payment-gateway-settings", component: PaymentGatewaySettings },
  { path: "/wallets", component: Wallets },
  { path: "/packages", component: Packages },
  { path: "/subscriptions", component: Subscriptions },
  { path: "/cron-logs", component: CronLogs },
];

const publicRoutes = [
  { path: "/exchange-code", component: ExchangeCode },
  { path: "/logout", component: Logout },
];

export { authProtectedRoutes, adminProtectedRoutes, publicRoutes };
