import {
  CreateSubscriptionDTO,
  PaymentStatuses,
  SubscriptionListType,
  UpdateSubscriptionDTO,
} from "core/entities/Subscription/SubscriptionTypes";
import { Subscription } from "../../entities/Subscription/Subscription";
import { SubscriptionRepository } from "../../entities/Subscription/SubscriptionRepository";

export interface SubscriptionService {
  GetSubscription(id: number): Promise<Subscription>;
  GetSubscriptions(
    skip: number,
    first: number,
    userId: string,
    packageId: number,
    paymentStatus: PaymentStatuses,
    price: string,
    dateGte: string,
    dateLte: string
  ): Promise<SubscriptionListType>;
  CreateSubscription(data: CreateSubscriptionDTO): Promise<Subscription>;
  UpdateSubscription(data: UpdateSubscriptionDTO): Promise<Subscription>;
  DeleteSubscription(id: number): Promise<Subscription>;
}

export class SubscriptionServiceImpl implements SubscriptionService {
  packageRepo: SubscriptionRepository;

  constructor(ar: SubscriptionRepository) {
    this.packageRepo = ar;
  }

  async GetSubscription(id: number): Promise<Subscription> {
    return this.packageRepo.GetById(id);
  }

  async GetSubscriptions(
    skip: number,
    first: number,
    userId: string,
    packageId: number,
    paymentStatus: PaymentStatuses,
    price: string,
    dateGte: string,
    dateLte: string
  ): Promise<SubscriptionListType> {
    return this.packageRepo.GetAll(
      skip,
      first,
      userId,
      packageId,
      paymentStatus,
      price,
      dateGte,
      dateLte
    );
  }

  async CreateSubscription(data: CreateSubscriptionDTO): Promise<Subscription> {
    return this.packageRepo.Create(data);
  }

  async UpdateSubscription(data: UpdateSubscriptionDTO): Promise<Subscription> {
    return this.packageRepo.Update(data);
  }

  async DeleteSubscription(id: number): Promise<Subscription> {
    return this.packageRepo.Delete(id);
  }
}
