import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CronLogFilters } from "./cronLogFilters";
import { SimpleTable } from "components/SimpleTable";
import { MyPagination } from "components/Pagination";
import { endDate, startDate } from "helpers/utils";
import moment from "moment";
import { getCronLogs } from "store/actions";
import { Card, CardBody } from "reactstrap";
import { useDataSource } from "hooks/useDataSource";
import Loading from "components/Loading/Index";

const tableColumns = [
  {
    key: "date",
    value: "Date",
    width: 150,
    formatter: (row, value) => moment(value).format("DD.MM.YYYY HH:mm"),
  },
  {
    key: "details",
    value: "Details",
  },
];

export const CronLogList = () => {
  const dispatch = useDispatch();
  const { cronLogs, loading } = useDataSource(
    () =>
      getCronLogs(
        pagination.skip,
        pagination.first,
        filters.dateGte,
        filters.dateLte
      ),
    state => state.CronLogState
  );
  const [pagination, setPagination] = useState({
    skip: 0,
    first: 50,
  });
  const [filters, setFilters] = useState({
    dateGte: null,
    dateLte: null,
  });

  useEffect(() => {
    dispatch(
      getCronLogs(
        pagination.skip,
        pagination.first,
        startDate(filters.dateGte),
        endDate(filters.dateLte)
      )
    );

    return () => {};
  }, [pagination, filters]);

  return (
    <>
      <CronLogFilters onChange={setFilters} filters={filters} />

      <Card>
        <CardBody>
          {cronLogs?.results && (
            <>
              <SimpleTable columns={tableColumns} rows={cronLogs.results} />
              <MyPagination
                skip={pagination.skip}
                first={pagination.first}
                totalCount={cronLogs.totalCount}
                onChange={setPagination}
              />
            </>
          )}
          {loading && <Loading />}
        </CardBody>
      </Card>
    </>
  );
};

CronLogList.propTypes = {};
