import {
  GET_PAYMENT_GATEWAY_SETTINGS,
  GET_PAYMENT_GATEWAY_SETTINGS_FAIL,
  GET_PAYMENT_GATEWAY_SETTINGS_SUCCESS,
  RESET_STATUS,
  UPDATE_PAYMENT_GATEWAY_SETTINGS,
  UPDATE_PAYMENT_GATEWAY_SETTINGS_FAIL,
  UPDATE_PAYMENT_GATEWAY_SETTINGS_SUCCESS,
} from "./actionTypes";

export const getPaymentGatewaySettings = () => ({
  type: GET_PAYMENT_GATEWAY_SETTINGS,
});

export const getPaymentGatewaySettingsSuccess = settings => ({
  type: GET_PAYMENT_GATEWAY_SETTINGS_SUCCESS,
  payload: settings,
});

export const getPaymentGatewaySettingsFail = error => ({
  type: GET_PAYMENT_GATEWAY_SETTINGS_FAIL,
  payload: error,
});

export const updatePaymentGatewaySettings = data => ({
  type: UPDATE_PAYMENT_GATEWAY_SETTINGS,
  payload: data,
});

export const updatePaymentGatewaySettingsSuccess = data => ({
  type: UPDATE_PAYMENT_GATEWAY_SETTINGS_SUCCESS,
  payload: data,
});

export const updatePaymentGatewaySettingsFail = error => ({
  type: UPDATE_PAYMENT_GATEWAY_SETTINGS_FAIL,
  payload: error,
});

export const resetStatus = () => ({
  type: RESET_STATUS,
});
