import React from "react";
import MetaTags from "react-meta-tags";
import { Card, CardBody, Container } from "reactstrap";
import { SettingsForm } from "./settingsForm";

const PaymentGatewaySettings = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Ödeme Altyapısı Ayarları</title>
        </MetaTags>
        <Container fluid>
          <h4 className="mb-3">Ödeme Altyapısı Ayarları</h4>
        </Container>

        <Card>
          <CardBody>
            <SettingsForm />
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default PaymentGatewaySettings;
