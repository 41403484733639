import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDataSource } from "hooks/useDataSource";
import { Badge, Card, CardBody, Container } from "reactstrap";
import { getMySubscriptions, getSubscriptions } from "store/actions";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { SimpleTable } from "components/SimpleTable";
import { MyPagination } from "components/Pagination";
import Loading from "components/Loading/Index";

const tableColumns = [
  {
    key: "date",
    value: "Date",
    formatter: (row, value) => moment(value).format("DD.MM.YYYY HH:mm"),
  },
  {
    key: "userName",
    value: "User",
  },
  {
    key: "package",
    value: "Package",
    formatter: (row, value) => value.name,
  },
  {
    key: "price",
    value: "Price",
  },
  {
    key: "duration",
    value: "Duration",
  },
  {
    key: "expireDate",
    value: "Expire Date",
    formatter: (row, value) => moment(value).format("DD.MM.YYYY HH:mm"),
  },
  {
    key: "paymentStatus",
    value: "Status",
    formatter: (row, value) => {
      const expireDate = moment(row.expireDate);
      const left = expireDate.diff(moment(), "days", true);
      const roundedLeft = Math.round(left);
      if (left > 15)
        return (
          <Badge color="success" className="bg-success font-size-12">
            {roundedLeft} Gün Kaldi
          </Badge>
        );
      if (left > 5)
        return (
          <Badge color="warning" className="bg-warning font-size-12">
            {roundedLeft} Gün Kaldi
          </Badge>
        );
      if (left > 1)
        return (
          <Badge color="danger" className="bg-danger font-size-12">
            {roundedLeft} Gün Kaldi
          </Badge>
        );
      if (left > 0)
        return (
          <Badge color="danger" className="bg-danger font-size-12">
            Bugün Bitiyor
          </Badge>
        );

      return (
        <Badge color="secondary" className="bg-secondary font-size-12">
          Bitti
        </Badge>
      );
    },
  },
];

export const MySubscriptionList = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.Auth.auth);
  const [pagination, setPagination] = useState({
    skip: 0,
    first: 50,
  });
  const { mySubscriptions, loading } = useDataSource(
    () =>
      getMySubscriptions(
        pagination.skip,
        pagination.first,
        user.userId,
        null,
        "COMPLETED"
      ),
    state => state.SubscriptionState
  );

  useEffect(() => {
    dispatch(
      getMySubscriptions(
        pagination.skip,
        pagination.first,
        user.userId,
        null,
        "COMPLETED"
      )
    );

    return () => {};
  }, [pagination]);

  return (
    <Card>
      <CardBody>
        {mySubscriptions?.results && (
          <>
            <SimpleTable
              columns={tableColumns}
              rows={mySubscriptions.results}
            />
            <MyPagination
              skip={pagination.skip}
              first={pagination.first}
              totalCount={mySubscriptions.totalCount}
              onChange={setPagination}
            />
          </>
        )}
        {loading && <Loading />}
      </CardBody>
    </Card>
  );
};
