import { client } from "../../HttpClient";
import {
  GET_WALLETS,
  CREATE_WALLET,
  UPDATE_WALLET,
  DELETE_WALLET,
  GET_WALLET,
  GET_WALLET_BALANCE,
} from "./graphql";

export const getWallet = async (id: number): Promise<any> => {
  const response = await client.query({
    query: GET_WALLET,
    variables: { id },
  });
  return response.data.wallet;
};

export const getWalletBalance = async (id: number): Promise<any> => {
  const response = await client.query({
    query: GET_WALLET_BALANCE,
    variables: { id },
  });
  return response.data.walletBalance;
};

export const getWallets = async (): Promise<any> => {
  const response = await client.query({
    query: GET_WALLETS,
  });
  return response.data.wallets;
};

export const createWallet = async (data: object): Promise<any> => {
  const response = await client.mutate({
    mutation: CREATE_WALLET,
    variables: {
      data: data,
    },
  });
  return response.data.createWallet.wallet;
};

export const updateWallet = async (data: object): Promise<any> => {
  const response = await client.mutate({
    mutation: UPDATE_WALLET,
    variables: {
      data: data,
    },
  });
  return response.data.updateWallet.wallet;
};

export const deleteWallet = async (id: number): Promise<any> => {
  const response = await client.mutate({
    mutation: DELETE_WALLET,
    variables: {
      id,
    },
  });
  return response.data.deleteWallet.wallet;
};
