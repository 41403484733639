import { client } from "../../HttpClient";
import { GET_PAYMENT_STATUS, CREATE_PAYMENT, CANCEL_PAYMENT } from "./graphql";

export const getPaymentStatusById = async (id: number): Promise<any> => {
  const response = await client.query({
    query: GET_PAYMENT_STATUS,
    variables: { id },
    fetchPolicy: "network-only",
  });
  return response.data.subscription;
};

export const createPayment = async (data: object): Promise<any> => {
  const response = await client.mutate({
    mutation: CREATE_PAYMENT,
    variables: {
      data: data,
    },
  });
  return response.data.createPayment.payment;
};

export const cancelPayment = async (data: object): Promise<any> => {
  const response = await client.mutate({
    mutation: CANCEL_PAYMENT,
    variables: {
      data: data,
    },
  });
  return response.data.cancelPayment.payment;
};
