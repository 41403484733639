import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import { Button, Container, Modal, ModalBody } from "reactstrap";
import { WalletUpdate } from "./walletUpdateForm";
import { WalletCreateForm } from "./walletCreateForm";
import { WalletList } from "./walletList";

const Wallets = () => {
  const [openModal, setOpenModal] = useState({
    isOpen: false,
    isCreate: false,
    isUpdate: false,
    selectedId: null,
  });

  const onRowClick = row => {
    setOpenModal(prevState => {
      return { ...prevState, isOpen: true, isUpdate: true, selectedId: row.id };
    });
  };

  const handleCreate = () => {
    setOpenModal(prevState => {
      return { ...prevState, isOpen: true, isCreate: true };
    });
  };

  const closeModal = () => {
    setOpenModal({
      isOpen: false,
      isCreate: false,
      isUpdate: false,
      selectedId: null,
    });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Cüzdanlar</title>
        </MetaTags>
        <Container fluid>
          <Button
            color="primary"
            className="float-end"
            onClick={handleCreate}
            style={{ marginTop: -5 }}
          >
            <i className="fa fa-plus"></i> Ekle
          </Button>
          <h4 className="mb-3">Cüzdanlar</h4>
        </Container>

        <WalletList onRowClick={onRowClick} />

        <Modal isOpen={openModal.isOpen} toggle={closeModal}>
          <ModalBody>
            <Button close onClick={closeModal} />
            {openModal.isCreate && <WalletCreateForm />}
            {openModal.isUpdate && <WalletUpdate id={openModal.selectedId} />}
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default Wallets;
