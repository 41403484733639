import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Redirect } from "react-router-dom";
import { exchangeCode } from "store/actions";
import Loading from "components/Loading/Index";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ExchangeCode = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector(state => state.Auth);
  let query = useQuery();

  useEffect(() => {
    const code = query.get("code");
    dispatch(exchangeCode(code));

    return () => {};
  }, []);

  return <>{auth?.accessToken ? <Redirect to="/" /> : <Loading />}</>;
};

export default ExchangeCode;
