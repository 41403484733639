import { WalletBalanceDTO, WalletDTO } from "core/entities/Wallet/WalletTypes";
import { Wallet } from "../../entities/Wallet/Wallet";
import { WalletRepository } from "../../entities/Wallet/WalletRepository";
import services from "../Services";

export class WalletRepositoryImpl implements WalletRepository {
  async GetById(id: number): Promise<Wallet> {
    const item = await services.api.getWallet(id);
    return new Wallet(
      item.id,
      item.exchange,
      item.subAccount,
      item.address,
      item.apiKey,
      item.apiSecret,
      item.isMain,
      item.transferFee
    );
  }

  async GetBalanceById(id: number): Promise<WalletBalanceDTO> {
    const item = await services.api.getWalletBalance(id);
    return item;
  }

  async GetAll(): Promise<Wallet[]> {
    const items = await services.api.getWallets();
    return items.map(
      (item: WalletDTO) =>
        new Wallet(
          item.id,
          item.exchange,
          item.subAccount,
          item.address,
          item.apiKey,
          item.apiSecret,
          item.isMain,
          item.transferFee
        )
    );
  }
  async Create(data: Wallet): Promise<Wallet> {
    const item: WalletDTO = await services.api.createWallet(data);
    return new Wallet(
      item.id,
      item.exchange,
      item.subAccount,
      item.address,
      item.apiKey,
      item.apiSecret,
      item.isMain,
      item.transferFee
    );
  }

  async Update(data: Wallet): Promise<Wallet> {
    const item: WalletDTO = await services.api.updateWallet(data);
    return new Wallet(
      item.id,
      item.exchange,
      item.subAccount,
      item.address,
      item.apiKey,
      item.apiSecret,
      item.isMain,
      item.transferFee
    );
  }

  async Delete(id: number): Promise<number> {
    const item: WalletDTO = await services.api.deleteWallet(id);
    return id;
  }
}
