import { all, fork } from "redux-saga/effects";

//public
import LayoutSaga from "./layout/saga";
import AuthSaga from "./auth/saga";
import PackageSaga from "./package/saga";
import PaymentGatewaySettingsSaga from "./payment-gateway-settings/saga";
import WalletSaga from "./wallet/saga";
import RoleSaga from "./role/saga";
import SubscriptionSaga from "./subscription/saga";
import UserSaga from "./user/saga";
import PaymentSaga from "./payment/saga";

import ecommerceSaga from "./e-commerce/saga";
import calendarSaga from "./calendar/saga";
import chatSaga from "./chat/saga";
import cryptoSaga from "./crypto/saga";
import invoiceSaga from "./invoices/saga";
import projectsSaga from "./projects/saga";
import tasksSaga from "./tasks/saga";
import mailsSaga from "./mails/saga";
import contactsSaga from "./contacts/saga";
import dashboardSaga from "./dashboard/saga";
import dashboardSaasSaga from "./dashboard-saas/saga";
import CronLogSaga from "./cron-log/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),
    fork(AuthSaga),
    fork(PackageSaga),
    fork(PaymentGatewaySettingsSaga),
    fork(WalletSaga),
    fork(RoleSaga),
    fork(SubscriptionSaga),
    fork(UserSaga),
    fork(PaymentSaga),
    fork(CronLogSaga),

    fork(ecommerceSaga),
    fork(calendarSaga),
    fork(chatSaga),
    fork(mailsSaga),
    fork(cryptoSaga),
    fork(invoiceSaga),
    fork(projectsSaga),
    fork(tasksSaga),
    fork(contactsSaga),
    fork(dashboardSaga),
    fork(dashboardSaasSaga),
  ]);
}
